import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '../../Spinner';
import { CustomApiErr } from '../../CustomApiErr';
import { DataTable } from '../../DataTable';
import { isEmpty, transformData } from '../../_helpers';
import { reportReserves } from '../templates/reserveTemplate';
import { ComponentTitle } from '../../../css/_styledComponents';

export default function ReserveCurrent ({
  guidValue = {},
  axiosRequest = () => {},
  endpoint = '',
  isRepayOnly = false
}) {
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [status, setStatus] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    !isEmpty(guidValue) && getData();
  }, [guidValue]);

  const getData = async () => {
    setErr(null);
    setStatus(null);
    setSpinnerLoading(true);
    const apiRes = await axiosRequest({
      fullPageLoad: false,
      url: endpoint,
      method: 'get',
      requestGuid: guidValue.relationshipIdList === 'all' ? {} : guidValue
    });
    if (apiRes?.errorDetails instanceof Error) {
      setSpinnerLoading(false);
      setErr(apiRes?.state?.err);
      setStatus(apiRes.state.status);
    } else {
      const newTableData = transformData({
        data: apiRes?.data,
        toSchema: 'frontendCurrent',
        template: reportReserves,
        version: '1.0'
      });
      setTableData(newTableData);
      setSpinnerLoading(false);
      setErr(null);
      setStatus(apiRes?.state?.status);
    }
  };

  return (
    <div>
      <ComponentTitle $buttonType="button" style={{ margin: '10px' }}>
        <h2>
          Current Reserve/Merchant Position
        </h2>
      </ComponentTitle>
      <div
        id="dataTableWrapper"
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <Spinner loading={spinnerLoading} />
        {(err || isEmpty(guidValue)) ? (
          <CustomApiErr
            loading={spinnerLoading}
            status={status}
            customErr={err}
          />
        ) : (
          <>
            {!isRepayOnly && (
              <div style={{ color: 'var(--color-warning)' }}>
                Complete reserve data is only available to MIDs with a Repay processor.
                MIDs with a different processor will only show merchant position.
              </div>
            )}
            <DataTable
              passedData={tableData}
              downloadFile={{ fileName: 'Portfolio Reserves Totals' }}
              headerStyle={{ minWidth: '10em' }}
              modal={false}
              defaultSortCol="mid"
              direction="desc"
            />
          </>

        )}
      </div>
    </div>
  );
}

ReserveCurrent.propTypes = {
  guidValue: PropTypes.oneOfType([PropTypes.object]),
  endpoint: PropTypes.string,
  axiosRequest: PropTypes.func,
  isRepayOnly: PropTypes.bool
};
