import * as React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from '@f1/shared/src/routing/withRouter';
import { isBool, isPublicUrl, minToMs } from '@f1/shared/src/_helpers';
import {
  closeWarningAlert,
  displayWarningAlert,
  getDiffNowVsLastClick,
  getRemainingMinLeft
} from '@f1/shared/src/_pollingUtils';
import * as actionCreators from '../redux/actions/actionCreators';
import { axiosRequest, handleClearLocalDB, handleSignOutPortal } from '../utils';

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authenticate.isAuthenticated,
    user: state.authenticate.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export class Polling extends React.Component {
  constructor(props) {
    super(props);
    this.defaultPollingMs = minToMs(15); // Poll every 15 min
    this.mounted = false;
    this.state = {
      tabActive: true
    };
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.mounted = true;
      this.checkToken();
    }
    !isAuthenticated && handleClearLocalDB();
    window.addEventListener('focus', this.onFocus); // when tab becomes active
    window.addEventListener('blur', this.onBlur); // when tab becomes inactive
  }

  componentDidUpdate(prevProps, prevState) {
    const { isAuthenticated, location, user } = this.props;
    const { tabActive } = this.state;
    const justSignedIn = prevProps.isAuthenticated !== isAuthenticated && isAuthenticated;
    const alreadySignedIn = prevProps.isAuthenticated === isAuthenticated && isAuthenticated;
    const tabBecameActive =
      alreadySignedIn &&
      // user already signed in, tab was previously inactive, then became active again
      tabActive !== prevState.tabActive &&
      tabActive;
    const lastClickChanged =
      alreadySignedIn &&
      // user already signed in, user's last click timestamp changed
      prevProps.user?.accessToken?.lastClickTimestamp !== user?.accessToken?.lastClickTimestamp;
    if (justSignedIn || tabBecameActive || lastClickChanged) {
      this.checkToken({ justSignedIn });
    } else if (
      prevProps.location.state?.clearPolling !== location.state?.clearPolling &&
      location.state?.clearPolling
    ) {
      // user clicks sign out
      this.clearTimers();
      this.signOut({ isSignOutClick: true });
    } else if (
      // `clearPolling` should NOT exist if user gets auto-signed out
      !isBool(location.state?.clearPolling) &&
      prevProps.isAuthenticated !== isAuthenticated &&
      !isAuthenticated
    ) {
      // unauthorized user (401 error on handleApiError method)
      this.clearTimers();
      this.signOut({ clearStore: false }); // store has already been cleared
    }
  }

  componentWillUnmount() {
    this.clearTimers();
    window.removeEventListener('focus', this.onFocus); // when tab becomes active
    window.removeEventListener('blur', this.onBlur); // when tab becomes inactive
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  checkToken = async (options) => {
    const { justSignedIn } = options || {};
    const { isAuthenticated, user } = this.props;
    // Get diff between now & last click time
    const minDiff = await getDiffNowVsLastClick({
      axiosRequest,
      justSignedIn,
      isAuthenticated,
      user
    });
    if (minDiff < 45) {
      this.clearWarning();
      if (!this.poll) {
        // set polling
        // If diff between now & last click is < 45 min, start polling
        this.setPollInterval();
      }
    } else if (minDiff >= 45 && minDiff <= 59) {
      // Diff between now & last click is between 45-59 min ago, warn user
      this.handleWarning();
    } else {
      // Last click was > 1 hour ago from now -> sign out
      this.signOut();
      this.clearTimers();
    }
  };

  onFocus = () => {
    this.mounted = true;
    this.updateState({ tabActive: true });
  };

  onBlur = () => {
    this.updateState({ tabActive: false });
    this.mounted = false;
  };

  setPollInterval = () => {
    this.poll = setInterval(this.checkToken, this.defaultPollingMs);
  };

  clearPollInterval = () => clearInterval(this.poll);

  setSessionTimeout = async () => {
    // Timeout to auto-sign-out user if already warned & no recent click activity
    const { isAuthenticated, user } = this.props;
    const minLeft = await getRemainingMinLeft({ axiosRequest, isAuthenticated, user });
    const timeRemaining = minToMs(minLeft);
    this.sessionTimeout = setTimeout(this.signOut, timeRemaining);
  };

  clearSessionTimeout = () => clearTimeout(this.sessionTimeout);

  clearWarning = () => {
    const { alertBar } = this.props;
    this.timeRemaining && closeWarningAlert({ alertBar });
    this.timeRemaining && this.clearTimeRemainingInterval();
    this.sessionTimeout && this.clearSessionTimeout();
  };

  handleWarning = async () => {
    const { alertBar, isAuthenticated, user } = this.props;
    if (!isPublicUrl) {
      await displayWarningAlert({ alertBar, isAuthenticated, user });
    }
    this.timeRemaining && this.clearTimeRemainingInterval();
    this.poll && this.clearPollInterval();
    this.sessionTimeout && this.clearSessionTimeout();
    if (!isPublicUrl) {
      this.setTimeRemainingInterval();
      this.setSessionTimeout();
    }
  };

  setTimeRemainingInterval = () => {
    this.timeRemaining = setInterval(this.handleWarning, this.defaultPollingMs);
  };

  clearTimeRemainingInterval = () => clearInterval(this.timeRemaining);

  clearTimers = () => {
    this.clearPollInterval();
    this.clearSessionTimeout();
    this.clearTimeRemainingInterval();
  };

  signOut = (options) => {
    const { isSignOutClick, clearStore = true } = options || {};
    const { navigate, resetStore, alertBar } = this.props;
    localStorage.removeItem('applicationId');
    handleSignOutPortal({
      isSignOutClick,
      clearStore,
      navigate,
      resetStore,
      alertBar
    });
  };

  render() {
    return <div {...this.props} />;
  }
}

Polling.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.shape({
    accessToken: PropTypes.shape({
      csrfToken: PropTypes.string,
      lastClickTimestamp: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
      ]),
      exp: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])
    })
  }),
  navigate: PropTypes.func,
  alertBar: PropTypes.func,
  resetStore: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      clearPolling: PropTypes.bool
    })
  })
};

Polling.defaultProps = {
  isAuthenticated: false,
  user: {
    accessToken: {
      csrfToken: '',
      lastClickTimestamp: 0,
      exp: 0
    }
  },
  navigate: () => {},
  alertBar: () => {},
  resetStore: () => {},
  location: {
    state: {
      clearPolling: false
    }
  }
};

const withPolling = (Component) =>
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      class extends Polling {
        render() {
          return <Component {...this.props} />;
        }
      }
    )
  );

export default withPolling;
