export const partnerHierarchyTemplate = {
  frontend: (schema, version) => {
    // GET
    if (version === '1.0') {
      const { apiData = {}, relationshipId } = schema;
      const { hierarchyData = [] } = apiData || {};
      // representative: relationship that was selected at new application/associated with MPA
      const repMatch =
        hierarchyData.find((h) => h.relationship?.relationshipId === relationshipId) || {};
      // partner: top most partner in the hierarchy data (ie, the first item in array)
      const partnerMatch = hierarchyData[0] || {};
      return {
        relationshipCode: repMatch?.relationship?.relationshipCode,
        representativeName: repMatch?.relationship?.relationshipName,
        representativeNumber: repMatch?.relationship?.relationshipCode,
        referralPartnerName: partnerMatch?.partner?.partnerName,
        referralPartnerNumber: partnerMatch?.partner?.partnerBusinessCode
      };
    }
    return schema;
  }
};

export default partnerHierarchyTemplate;
