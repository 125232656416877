import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// import each reducer
import authenticate from './authenticate';
import dataUpdate from './dataUpdate';
import filterData from './filterData';
import toggleLoader from './toggleLoader';
import activeGuid from './activeGuid';
import alertBar from './alertBar';
import notifications from './notifications';
import forgotPassword from './forgotPassword';
import siteModal from './siteModal';
import updatePage from './updatePage';
import appState from './appState';
import activeDownline from './activeDownline';

const appReducer = combineReducers({
  // ADD each imported reducer
  activeDownline,
  activeGuid,
  alertBar,
  authenticate,
  appState,
  dataUpdate,
  filterData,
  forgotPassword,
  notifications,
  siteModal,
  toggleLoader,
  updatePage,
  routing: routerReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
