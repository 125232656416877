const backendFormatCheckboxList = (array, options) => {
  const { list = [] } = options || {};
  const dataValueMap = array.reduce((acc, item) => ({ ...acc, ...item }), {});
  const customValue = list.reduce((acc, item) => {
    const itemValue = item.value;
    return { ...acc, [itemValue]: dataValueMap[itemValue] || false };
  }, {});
  return customValue;
};

export const notificationFields = {
  actionRequiredReport: {
    id: 'actionRequiredReport',
    actionRequiredReportList: {
      id: 'actionRequiredReportList',
      list: [
        { title: 'Enable Email Reports', value: 'reportsActive' },
        { title: 'No Starts', value: 'noStarts' },
        { title: 'Just Test Processing', value: 'justTestProcessing' },
        { title: 'Stopped Processing', value: 'stoppedProcessing' },
        { title: 'Terminated', value: 'terminated' },
        { title: 'Running Hot', value: 'runningHot' },
        { title: 'Chargeback Health', value: 'chargebackHealth' },
        { title: 'Ach Reject', value: 'achReject' }
      ],
      label: 'Emailed Reports',
      isCheckboxList: true,
      noBoxBorder: true,
      checkboxStyle: { type: 'mini' },
      type: 'mini',
      containerStyle: { width: 'auto', flexDirection: 'column' },
      fieldType: 'checkboxList',
      required: false,
      backendFormat: backendFormatCheckboxList
    },
    reportFrequency: {
      id: 'reportFrequency',
      name: 'reportFrequency',
      label: 'Report Frequency',
      list: [
        { value: 'daily', title: 'Daily' },
        { value: 'weekly', title: 'Weekly' },
        { value: 'monthly', title: 'Monthly' }
      ],
      fieldType: 'combobox',
      wrapperStyle: { minWidth: '150px' }
    }
  },
  crabNotification: {
    id: 'crabNotification',
    crabNotificationList: {
      fieldType: 'checkboxList',
      id: 'crabNotificationList',
      list: [
        { title: 'Enable Email Reports', value: 'crabReportsActive' },
        { title: 'Approval', value: 'approval' },
        { title: 'Withdrawn', value: 'withdrawn' },
        { title: 'Decline', value: 'decline' },
        { title: 'Pends Publication', value: 'pendsPublication' },
        { title: 'Initial Submission', value: 'initialSubmission' }
      ],
      isCheckboxList: true,
      noBoxBorder: true,
      checkboxStyle: { type: 'mini' },
      type: 'mini',
      containerStyle: { width: 'auto' },
      required: false,
      backendFormat: backendFormatCheckboxList
    }
  }
};

export default notificationFields;
