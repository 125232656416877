import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

const createMockMonthlySummaryData = (options) => {
  const { relationshipList = [] } = options || {};
  const mockResponse = (relationshipList || []).map((r) => {
    const mockSummary = {
      relationshipName: r.title || 'Mock Relationship Name',
      relationshipCode: r.relationshipCode || '200000',
      relationshipId: r.value || 'mock-relationship-id',
      total: getMockValue(),
      undetermined: getMockValue()
    };
    return mockSummary;
  });
  return mockResponse;
};

export const mockMonthlySummaryGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: {
      applicationSummaryData: {
        header: [
          {
            order: 1,
            key: 'relationshipName',
            englishName: 'Relationship Name'
          },
          {
            order: 2,
            key: 'relationshipCode',
            englishName: 'Relationship Code'
          },
          {
            order: 3,
            key: 'total',
            englishName: 'Total'
          },
          {
            order: 4,
            key: 'undetermined',
            englishName: 'MCC Not Yet Assigned'
          }
        ],

        data: createMockMonthlySummaryData(options)
      }
    }
  };
};

export const mockMonthlySummarySubmissionGetFormatted = mockMonthlySummaryGet(
  {},
  { requestType: 'submission', yearMonth: '2022' },
  { relationshipIdList: ['mockGuid'] }
)[''];

export const mockMonthlySummaryApprovalGetFormatted = mockMonthlySummaryGet(
  {},
  { requestType: 'approval', yearMonth: '2022' },
  { relationshipIdList: ['mockGuid'] }
)[''];

export default mockMonthlySummaryGet;
