import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers/index';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    // only persist these reducers
    'appState',
    'authenticate',
    'activeGuid',
    'activeDownline',
    'dataUpdate',
    'filterData',
    'forgotPassword',
    'signup',
    'user'
  ]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  // Pass previously created persisted reducer
  reducer: persistedReducer,
  // No middleware, but need this
  middleware: () => [],
  // Turn off devtools in prod, or pass options in dev
  devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);
export default store;
