import { isEmpty } from '@f1/shared/src/_helpers';

export const initialState = {
  nestedMenus: {
    partnerTree: ''
  },
  navLinks: {
    boardingEnabled: false
  },
  page: {
    // [page]: <tab index>
  },
  userPreferences: {
    application: {
      autosave: false
    }
  },
  serverMaintenance: false
};

function appState(state = initialState, actions) {
  const newPreferences =
    actions.userPreferences &&
    Object.entries(actions.userPreferences).reduce(
      (prefs, [page, valueObject]) => ({
        ...prefs,
        [page]: {
          ...state.userPreferences[page],
          ...valueObject
        }
      }),
      {}
    );
  switch (actions.type) {
    case 'APP_STATE':
      return {
        ...state,
        ...(actions.nestedMenus && {
          nestedMenus: {
            ...state.nestedMenus,
            ...actions.nestedMenus
          }
        }),
        ...(actions.navLinks && {
          navLinks: {
            ...state.navLinks,
            ...actions.navLinks
          }
        }),
        ...(actions.page && {
          page: {
            ...state.page,
            ...actions.page
          }
        }),
        ...(actions.userPreferences &&
          newPreferences && {
            userPreferences: {
              ...state.userPreferences,
              ...newPreferences
            }
          }),
        ...(!isEmpty(actions) &&
          typeof actions.serverMaintenance === 'boolean' && {
            serverMaintenance: actions.serverMaintenance
          })
      };
    default:
      return state;
  }
}

export default appState;
