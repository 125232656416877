import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { usePrevious } from '@dnd-kit/utilities';
import { Spinner } from '../../Spinner';
import { CustomApiErr } from '../../CustomApiErr';
import { ChartWrapper } from '../../ChartWrapper';
import { DataTable } from '../../DataTable';
import {
  getThisYearMonthDay, isEmpty, isEqual, transformData
} from '../../_helpers';
import { reportReserves } from '../templates/reserveTemplate';
import { AdvancedFilterBar } from '../../../css/_styledFormComponents';
import { Button } from '../../Button';
import { Input } from '../../Input';

export default function ReserveDetails ({
  guidValue = {},
  reserveDetailEndpoint = '',
  axiosRequest = () => {},
  isRepayOnly = true
}) {
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [status, setStatus] = useState(null);
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [fromDate, setFromDate] = useState(getThisYearMonthDay());
  const [fromDateValid, setFromDateValid] = useState(true);
  const [toDate, setToDate] = useState(getThisYearMonthDay());
  const [toDateValid, setToDateValid] = useState(true);
  const [formInProgress, setFormInProgress] = useState(false);
  const previousApiCallOptions = usePrevious(guidValue);

  useEffect(() => {
    !isEmpty(guidValue) && !isEqual(previousApiCallOptions, guidValue) && checkDateRange();
    isEmpty(guidValue) && setToDate(getThisYearMonthDay()) && setFromDate(getThisYearMonthDay());
  }, [guidValue]);

  const getData = async () => {
    setErr(null);
    setStatus(null);
    setSpinnerLoading(true);
    const apiRes = await axiosRequest({
      fullPageLoad: false,
      url: reserveDetailEndpoint,
      method: 'get',
      requestGuid: guidValue.relationshipIdList === 'all' ? {} : guidValue,
      config: {
        params: {
          fromDate,
          toDate
        }
      }
    });
    if (apiRes?.errorDetails instanceof Error) {
      setSpinnerLoading(false);
      setErr(apiRes?.state?.err);
      setStatus(apiRes.state.status);
    } else {
      setFormInProgress(false);
      const {
        chartData: newChartData = {},
        tableData: newTableData = []
      } = transformData({
        data: apiRes?.data,
        toSchema: 'frontendDetails',
        template: reportReserves,
        version: '1.0'
      });
      setChartData(newChartData);
      setTableData(newTableData);
      setSpinnerLoading(false);
      setErr(null);
      setStatus(apiRes?.state?.status);
    }
  };

  const checkDateRange = () => {
    const [yearTo, monthTo, dayTo] = (`${toDate}`).split('-');
    const [yearFrom, monthFrom, dayFrom] = (`${fromDate}`).split('-');
    if (yearFrom < yearTo || (yearFrom === yearTo && monthFrom < monthTo) ||
    (yearFrom === yearTo && monthFrom === monthTo && dayFrom <= dayTo)) {
      getData();
    } else {
      swal({
        title: 'Date Range Incorrect',
        text: 'The date range you have selected is not possible. Please change your date range selection.',
        buttons: ['OK'],
        className: 'swal-corvia-error',
        dangerMode: false,
        icon: 'warning',
        closeOnClickOutside: true,
        closeOnEsc: true
      });
    }
  };

  return (
    <div>
      <div
        id="dataTableWrapper"
        style={{ position: 'relative' }}
      >
        <Spinner loading={spinnerLoading} />
        {(err || isEmpty(guidValue)) ? (
          <CustomApiErr
            loading={spinnerLoading}
            status={status}
            {...isEmpty(guidValue) && { customMessage: 'Make a selection above to see reserve details' }}
            customErr={err}
          />
        ) : (
          <>
            <AdvancedFilterBar id="advancedFilters" style={{ width: '100%', display: 'flex' }}>
              <Input
                id="yearMonthFrom"
                type="date"
                label="From"
                wrapperStyle={{ maxWidth: '120px' }}
                value={fromDate}
                required
                callback={(id, value, valid) => {
                  setFromDate(value);
                  setFromDateValid(valid);
                  setFormInProgress(true);
                }}
              />
              <Input
                id="yearMonthTo"
                type="date"
                label="To"
                wrapperStyle={{ maxWidth: '120px' }}
                value={toDate}
                required
                callback={(id, value, valid) => {
                  setToDate(value);
                  setToDateValid(valid);
                  setFormInProgress(true);
                }}
              />
              <Button
                type="button"
                style={{
                  maxWidth: '300px',
                  alignSelf: 'flex-end',
                  marginLeft: 'auto',
                  height: '30px'
                }}
                onClick={checkDateRange}
                id="applyAdvancedFilters"
                disabled={!fromDateValid || !toDateValid || !formInProgress}
              >
                Apply
              </Button>
            </AdvancedFilterBar>
            <ChartWrapper
              id="reserveGraph"
              data={chartData}
              axiosRequest={axiosRequest}
            />
            <hr />
            {!isRepayOnly && (
              <div style={{ color: 'var(--color-warning)' }}>
                Complete reserve data is only available to MIDs with a Repay processor.
                MIDs with a different processor will only show merchant position.
              </div>
            )}
            <DataTable
              passedData={tableData}
              downloadFile={{ fileName: 'Reserve Details', sheetName: 'Reserve Details' }}
              headerStyle={{ minWidth: '10em' }}
              modal={false}
              defaultSortCol="date"
              direction="desc"
            />
          </>
        )}
      </div>
    </div>
  );
}

ReserveDetails.propTypes = {
  guidValue: PropTypes.oneOfType([PropTypes.object]),
  reserveDetailEndpoint: PropTypes.string,
  axiosRequest: PropTypes.func,
  isRepayOnly: PropTypes.bool
};
