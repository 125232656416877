import React from 'react';
import PropTypes from 'prop-types';
import {
  endpoint,
  ignoreCase,
  isEmpty,
  isEqual
} from '@f1/shared/src/_helpers';
import ReserveDetails from '@f1/shared/src/reports/components/ReserveDetails';
import ReserveCurrent from '@f1/shared/src/reports/components/ReserveCurrent';
import {
  Button,
  Page,
  PageContent,
  PageHeader,
  Sidebar
} from '@f1/shared';
import { ComponentTitle } from '@f1/shared/css/_styledComponents';
import { axiosRequest, getAllRelationships } from '../utils';

export class ReserveDetailsWrapper extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.state = {
      sidebarOpen: false,
      isRepayOnly: false,
      action: ''
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.repayRelationshipsOnlyCheck();
  }

  componentDidUpdate (prevProps, prevState) {
    const { guidValue } = this.props;
    if (guidValue.relationshipIdList !== undefined && !isEqual(guidValue, prevProps.guidValue)) {
      this.repayRelationshipsOnlyCheck();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  }

  openSidebar = () => this.updateState({ sidebarOpen: true, action: 'reserveCurrent' });

  closeSidebar = () => this.updateState({ sidebarOpen: false, action: '' });

  repayRelationshipsOnlyCheck = () => {
    const { guidValue, relationshipTree } = this.props;
    const allRelationships =
      getAllRelationships(relationshipTree, { includeBankDetails: true });
    let isRepayOnly = true;
    if (isEmpty(guidValue.relationshipIdList) || guidValue.relationshipIdList === 'all') {
      isRepayOnly = allRelationships.every(aRelationship => ignoreCase(aRelationship.processor) === 'repay' || ignoreCase(aRelationship.processorName) === 'repay');
    } else {
      isRepayOnly = guidValue.relationshipIdList.every((aGuid) => {
        const { processor, processorName } = allRelationships
          .find(aRelationship => aRelationship.value === aGuid);
        return ignoreCase(processor) === 'repay' || ignoreCase(processorName) === 'repay';
      });
    }
    this.updateState({ isRepayOnly });
  }

  render () {
    const {
      isPartner,
      viewAsMerchant,
      guidValue
    } = this.props;
    const { sidebarOpen, isRepayOnly, action } = this.state;
    return (
      <Page>
        <PageHeader>
          <ComponentTitle $buttonType="button" style={{ margin: '0px 10px', justifyContent: 'space-between' }}>
            <h2 style={{ margin: '0px' }}>
              Reserve Details
            </h2>
            {isPartner && !viewAsMerchant && (
              <Button
                id="view-total-merchant-position"
                type="button"
                onClick={this.openSidebar}
                style={{ margin: '0px' }}
              >
                View Current Reserves
              </Button>
            )}
          </ComponentTitle>
        </PageHeader>
        <PageContent fullWidth>
          <ReserveDetails
            guidValue={guidValue}
            reserveDetailEndpoint={endpoint.report.reserveDetail}
            axiosRequest={axiosRequest}
            isRepayOnly={isRepayOnly}
          />
        </PageContent>
        <Sidebar
          sidebarOpen={sidebarOpen}
          callback={this.closeSidebar}
          axiosRequest={axiosRequest}
          width="90%"
        >
          {action === 'reserveCurrent' && (
            <ReserveCurrent
              guidValue={guidValue}
              axiosRequest={axiosRequest}
              endpoint={endpoint.report.reserveCurrent}
              isRepayOnly={isRepayOnly}
            />
          )}
        </Sidebar>
      </Page>
    );
  }
}

ReserveDetailsWrapper.propTypes = {
  isPartner: PropTypes.bool,
  viewAsMerchant: PropTypes.bool,
  guidValue: PropTypes.oneOfType([PropTypes.object]),
  relationshipTree: PropTypes.oneOfType([PropTypes.object])
};

ReserveDetailsWrapper.defaultProps = {
  isPartner: false,
  viewAsMerchant: false,
  guidValue: {},
  relationshipTree: {}
};

export default ReserveDetailsWrapper;
