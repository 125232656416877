import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

const valueProps = { min: 1, max: 60000 };
const ratioProps = { type: 'ratio' };

const createMockMiniReportData = () => ({
  authorizationMiniReport: {
    value: getMockValue(valueProps),
    percent: getMockValue(ratioProps) * 100,
    range: 'Past 30 Days'
  },
  chargebackMiniReport: {
    value: getMockValue(valueProps),
    percent: getMockValue(ratioProps) * 100,
    range: 'Past 30 Days'
  },
  salesMiniReport: {
    value: getMockValue(valueProps),
    percent: getMockValue(ratioProps) * 100,
    range: 'Past 30 Days'
  },
  refundMiniReport: {
    value: getMockValue(valueProps),
    percent: getMockValue(ratioProps) * 100,
    range: 'Past 30 Days'
  }
});

export const mockMiniReportDataBackend = createMockMiniReportData();

export const mockMiniReportDataFrontend = {
  // FE formatted data
  authorization: {
    percent: mockMiniReportDataBackend.authorizationMiniReport.percent,
    range: mockMiniReportDataBackend.authorizationMiniReport.range,
    value: mockMiniReportDataBackend.authorizationMiniReport.value
  },
  sales: {
    percent: mockMiniReportDataBackend.salesMiniReport.percent,
    range: mockMiniReportDataBackend.salesMiniReport.range,
    value: mockMiniReportDataBackend.salesMiniReport.value
  },
  chargeback: {
    percent: mockMiniReportDataBackend.chargebackMiniReport.percent,
    range: mockMiniReportDataBackend.chargebackMiniReport.range,
    value: mockMiniReportDataBackend.chargebackMiniReport.value
  },
  refund: {
    percent: mockMiniReportDataBackend.refundMiniReport.percent,
    range: mockMiniReportDataBackend.refundMiniReport.range,
    value: mockMiniReportDataBackend.refundMiniReport.value
  }
};

export const mockMiniReportGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: mockMiniReportDataBackend
  };
};
