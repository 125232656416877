import { setPastMonth, getThisYearMonth } from '@f1/shared/src/_helpers';

export const initialState = {
  filtered: [],
  activeFilters: {
    dateFrom: setPastMonth({ pastMonth: true, months: 6 }).dateFrom,
    dateTo: setPastMonth({ pastMonth: true, months: 6 }).dateTo,
    yearMonthFrom: getThisYearMonth({ lastMonth: true }),
    yearMonthTo: getThisYearMonth({ lastMonth: true }),
    searchFor: null,
    searchBy: null,
    guid: null,
    cardBrand: null,
    authorizationResponse: null,
    cardEntryMethod: null,
    status: null,
    downline: null,
    viewAsMerchant: false
  },
  perPage: 25,
  pageNum: 1,
  searchFor: null,
  report: ''
};

function filterData(state = initialState, actions) {
  switch (actions.type) {
    case 'FILTER_DATA':
      return {
        ...state,
        ...(actions.filtered && { filtered: actions.filtered }),
        ...(actions.activeFilters && {
          activeFilters: {
            ...state.activeFilters,
            ...actions.activeFilters,
            dateFrom:
              actions.activeFilters.dateFrom <= state.activeFilters.dateTo
                ? actions.activeFilters.dateFrom
                : state.activeFilters.dateFrom,
            dateTo:
              actions.activeFilters.dateTo >= state.activeFilters.dateFrom
                ? actions.activeFilters.dateTo
                : state.activeFilters.dateTo,
            ...(actions.activeFilters.yearMonthFrom && {
              yearMonthFrom: actions.activeFilters.yearMonthFrom,
              // TODO: MER-1848 use yearMonthTo
              yearMonthTo: actions.activeFilters.yearMonthFrom
            })
          }
        }),
        ...(actions.perPage && { perPage: actions.perPage }),
        ...(actions.pageNum && { pageNum: actions.pageNum }),
        ...(actions.report && { report: actions.report })
      };
    default:
      return state;
  }
}

export default filterData;
