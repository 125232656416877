export const defaultListHeight = 142; // default so list doesn't get too long

export const initialState = {
  pageName: '',
  dropdown: {
    listHeight: defaultListHeight,
    optionHeight: 55 // current min height of one dropdown option
  }
};

function updatePage(state = initialState, actions) {
  const { pageName, dropdown, type } = actions || {};
  switch (type) {
    case 'UPDATE_PAGE':
      return {
        ...state,
        ...(pageName && { pageName }),
        ...(dropdown && {
          dropdown: {
            ...state.dropdown,
            ...(dropdown.listHeight && {
              listHeight:
                dropdown.listHeight <= defaultListHeight ||
                dropdown.listHeight <= state.dropdown.listHeight
                  ? dropdown.listHeight
                  : defaultListHeight
            }),
            ...(dropdown.optionHeight && { optionHeight: dropdown.optionHeight })
          }
        })
      };
    default:
      return state;
  }
}

export default updatePage;
