export const mockPartnerRefundRes = (body, params, options) => {
  const { guid = [`"{}"`] } = options || {};
  return {
    [guid]: {
      header: {
        label: 'date',
        lines: ['count'],
        biaxial: ['count'],
        currency: true,
        biaxialCurrency: false
      },
      data: [
        {
          date: 'Feb',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        },
        {
          date: 'Mar',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        },
        {
          date: 'Apr',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        },
        {
          date: 'May',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        },
        {
          date: 'Jun',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        },
        {
          date: 'Jul',
          CTUH_ADD_2: 32.9,
          count: 21,
          CTUH_ADD_1: 16.45,
          TPPS_leaderboard: 0
        },
        {
          date: 'Aug',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }
      ]
    }
  };
};

export default mockPartnerRefundRes;
