import React from 'react';
import { companyName } from '@f1/shared/src/_helpers';
import { footerCSS } from './_styles';

const FooterLegal = () => (
  <div className="legal" style={footerCSS.legal}>
    <span className="text" style={footerCSS.legalText}>
      {}
      {`${companyName('justFirst')} is a registered ISO of: Wells Fargo Bank, N.A., Concord, CA, Cross River Bank, Fort Lee, NJ and MVB Bank, Fairmont, WV © ${companyName('justFirst')} 2022`}
    </span>
  </div>
);
export default FooterLegal;
