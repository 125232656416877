import { endpoint } from '@f1/shared/src/_helpers';
import { getMockDataFromMap } from '@f1/shared/src/_testHelpers';
import { sharedStubDataMap } from '@f1/shared/src/data/stubDataMap';
import { mockCrabExternalCommunicationGet } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationExternalCommunication';
import { mockBatchDetailsGet } from '@f1/shared/src/data/transactionReport/txnDetails';
import mockReportReserveDetailsGet, {
  mockReportReserveCurrentGet
} from '@f1/shared/src/data/reports/mockReserve';

import { mockACHDetailsGet } from '@f1/shared/src/data/reports/achDetails';
import { mockAchFundingGet } from '@f1/shared/src/data/reports/achFunding';
import { mockAchFundingSummeryGet } from '@f1/shared/src/data/reports/achFundingSummery';

import { mockDefaultPostRes } from '@f1/shared/src/data/_mockDataHelpers';
import { mockBoardingApplicationTaskPendGet } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationTaskPend';
import { mockBoardingApplicationDownloadUrlGet } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';
import { mockCrabApplicationMpaDetailGet } from '@f1/shared/src/data/crab/mockCrabV1ApplicationMpaDetail';
import { mockPrevetPut } from '@f1/shared/src/data/crab/mockPrevets';
import { mockFileV3Get } from '@f1/shared/src/data/file/mockFiles';
import { mockMerchantVarGet } from '@f1/shared/src/data/common/merchantVar';
import { getMockRelationshipList } from './_mockDataHelpers';
import { mockSignInRes } from './user/signIn';
import { mockCustomSettingsGet } from './infrastructure/customSettings';

import { mockMerchantLandingPageGet } from './merchant/landingPage';
import { mockMerchantRefundRes } from './merchant/refund';
import { mockMerchantAvsUsageRes } from './merchant/avsUsage';
import { mockMerchantAuthTimeRes } from './merchant/authTime';
import { mockSubmittingPartner } from './merchant/submittingPartner';
import { mockMerchantDelegates } from './merchant/delegate';

import { mockBoardingEnabledRes } from './parameter/boardingEnabled';
import { mockPartnerLandingPageGet } from './partnerPortal/landingPage/root';
import { mockPartnerIncomeGet } from './partnerPortal/income';
import { mockPartnerNotificationsGetRes } from './partnerPortal/landingPage/notifications';
import { mockPartnerRefundRes } from './partnerPortal/refund';
import { mockPartnerHealthGetRes } from './partnerPortal/health';
import { mockPartnerHierarchyGet } from './partner/mockPartnerHierarchyGet';

import { mockPartnerSummaryReportRes } from './partnerPortal/summaryReport';
import { mockPartnerMonthSummaryReportRes } from './partnerPortal/monthSummaryReport';

import { mockDelegateV2Get } from './delegate/mockDelegate';
import { mockCbCalculatorRes } from './report/cbCalculator';
import { mockMerchantOnHoldGet } from './report/onHold';
import { mockMiniReportGet } from './report/mini';
import { mockReportAuthorizationGet } from './report/authorization';
import { mockReportChargebackGet } from './report/chargeback';
import { mockReportSalesGet } from './report/sales';
import { mockReportRefundGet } from './report/refund';
import { mockMerchantDetailGet, mockStaticMerchantVar } from './report/merchantDetail';

import { mockApplicationStatusGet } from './report/applicationStatus';
import { mockMonthlySummaryGet } from './report/monthlySummary';
import {
  mockPagedTicketsGet,
  mockTicketsPutRes,
  mockTicketsRefundRequestPutRes
} from './partnerPortal/tickets';

import {
  mockPartnerCrabV1ApplicationGet,
  mockPartnerCrabV1ApplicationPut,
  mockPartnerCrabV1ApplicationDetailsGet,
  mockPartnerCrabV1ApplicationMpaPut
} from './crab/mockPartnerCrabV1Application';
import {
  mockPartnerCrabV1CloneDetailGet,
  mockPartnerCrabV1CloneGet,
  mockPartnerCrabV1ClonePut
} from './crab/mockPartnerCrabV1Clone';
import mockPartnerCrabV1ToMpa from './crab/mockPartnerCrabV1ToMpa';

import { mockSignUpPostRes } from './user/signUp';
import { mockNotificationConfigGet } from './partnerPortal/notificationConfig';
import { mockNotificationBellGet } from './partnerPortal/notificationBell';

import { mockPartnerPrevetsGet } from './crab/mockPartnerPrevetsGet';

export const getMockData = (options) => getMockDataFromMap({ ...options, map: stubDataMap });

export const stubDataMap = {
  ...sharedStubDataMap,
  // WEBSITE
  [endpoint.parameter.fifthThirdBankTerms]: {
    GET: { fifthThirdBankTerms: 'urltoterms' }
  },
  [endpoint.parameter.mvbTerms]: {
    GET: { mvbTerms: 'urltoterms' }
  },
  // USER
  [endpoint.user.signIn]: {
    POST: mockSignInRes
  },
  [endpoint.user.signUp]: {
    POST: (body, params, options) => mockSignUpPostRes(body, params, options)
  },
  [endpoint.user.signOut]: {
    POST: mockDefaultPostRes
  },
  [endpoint.user.forgotPasswordRequest]: {
    POST: mockDefaultPostRes
  },
  [endpoint.user.forgotPasswordVerify]: {
    POST: {}
  },
  [endpoint.user.sendPhoneVerification]: {
    POST: mockDefaultPostRes
  },
  [endpoint.user.verifyPhone]: {
    POST: mockDefaultPostRes
  },
  [endpoint.user.verifyEmail]: {
    POST: mockDefaultPostRes
  },
  [endpoint.user.sendEmailVerification]: {
    POST: mockDefaultPostRes
  },
  // PORTAL (BOTH MERCHANT & PARTNER)
  [endpoint.report.cbCalculator]: {
    GET: (body, params, options) => mockCbCalculatorRes(body, params, options)
  },
  [endpoint.delegateV2]: {
    GET: (body, params, options) =>
      mockDelegateV2Get(body, params, {
        ...options,
        relationshipList: getMockRelationshipList(options)
      }),
    POST: mockDefaultPostRes,
    DELETE: mockDefaultPostRes
  },
  [`${endpoint.report.root}/authorization`]: {
    GET: (body, params, options) => mockReportAuthorizationGet(body, params, options)
  },
  [`${endpoint.report.root}/chargeback`]: {
    GET: (body, params, options) =>
      mockReportChargebackGet(body, params, { ...options, reportType: 'chargeback' })
  },
  [`${endpoint.report.dispute}`]: {
    GET: (body, params, options) =>
      mockReportChargebackGet(body, params, { ...options, reportType: 'dispute' })
  },
  [`${endpoint.report.root}/sales`]: {
    GET: (body, params, options) => mockReportSalesGet(body, params, options)
  },
  [`${endpoint.report.root}/refund`]: {
    GET: (body, params, options) => mockReportRefundGet(body, params, options)
  },
  [endpoint.report.achDetails]: {
    GET: (body, params, options) => mockACHDetailsGet(body, params, options)
  },
  [endpoint.report.achFunding]: {
    GET: mockAchFundingGet
  },
  [endpoint.report.achFundingSummery]: {
    GET: mockAchFundingSummeryGet
  },
  [endpoint.report.onHold]: {
    GET: mockMerchantOnHoldGet
  },
  [endpoint.report.reserveDetail]: {
    GET: mockReportReserveDetailsGet
  },
  [endpoint.report.reserveCurrent]: {
    GET: mockReportReserveCurrentGet
  },
  [`${endpoint.report.root}/merchant/detail/v2`]: {
    GET: (body, params, options) =>
      mockMerchantDetailGet(body, params, {
        ...options,
        relationshipList: getMockRelationshipList(options)
      })
  },
  // PARTNER PORTAL
  [endpoint.parameter.boardingEnabled]: {
    GET: mockBoardingEnabledRes
  },
  [endpoint.partnerPortal.landingPage]: {
    GET: (body, params, options) => mockPartnerLandingPageGet(body, params, options)
  },
  [endpoint.partnerPortal.income]: {
    GET: (body, params, options) => mockPartnerIncomeGet(body, params, options)
  },
  [endpoint.partnerPortal.notifications]: {
    GET: (body, params, options) =>
      mockPartnerNotificationsGetRes(body, params, {
        ...options,
        relationshipList: getMockRelationshipList(options)
      })
  },
  [endpoint.partnerPortal.refund]: {
    GET: (body, params, options) => mockPartnerRefundRes(body, params, options)
  },
  [endpoint.portfolioSummaryReport]: {
    GET: (body, params, options) => mockPartnerSummaryReportRes(body, params, options)
  },
  [endpoint.partnerPortal.monthSummaryReport]: {
    GET: (body, params, options) => mockPartnerMonthSummaryReportRes(body, params, options)
  },
  [endpoint.partnerPortal.health]: {
    GET: (body, params, options) =>
      mockPartnerHealthGetRes(body, params, {
        ...options,
        relationshipList: getMockRelationshipList(options)
      })
  },
  [endpoint.report.batchDetails]: {
    GET: (body, params, options) => mockBatchDetailsGet(body, params, options)
  },
  [endpoint.partnerPortal.txns]: {
    GET: mockDefaultPostRes
  },
  [endpoint.partnerPortal.notificationBell]: {
    GET: mockNotificationBellGet
  },
  [endpoint.partnerPortal.notificationConfig]: {
    GET: (body, params, options) => mockNotificationConfigGet(body, params, options),
    POST: mockDefaultPostRes
  },
  [`${endpoint.user.root}/garbage@f1payments.com`]: {
    DELETE: mockDefaultPostRes
  },
  [endpoint.partnerPortal.ticket]: {
    GET: (body, params, options) =>
      mockPagedTicketsGet(body, params, {
        ...options,
        relationshipList: getMockRelationshipList(options)
      }),
    PUT: mockTicketsPutRes
  },
  [endpoint.refundRequest]: {
    PUT: mockTicketsRefundRequestPutRes
  },
  [endpoint.merchant.var]: {
    GET: (body, params, options) =>
      mockMerchantVarGet(body, params, {
        ...options,
        staticMap: {
          ...(options &&
            options.guid && {
              [options.guid]: mockStaticMerchantVar[options.guid] || mockStaticMerchantVar.default
            })
        }
      })
  },
  [endpoint.maintenance.merchant]: {
    POST: mockDefaultPostRes
  },
  [endpoint.partner.hierarchy]: {
    GET: (body, params, options) =>
      mockPartnerHierarchyGet(body, params, {
        ...options,
        relationshipList: getMockRelationshipList(options)
      })
  },
  [endpoint.report.applicationStatus]: {
    GET: (body, params, options) =>
      mockApplicationStatusGet(body, params, {
        ...options,
        relationshipList: getMockRelationshipList(options)
      })
  },
  [endpoint.report.monthlySummary]: {
    GET: (body, params, options) =>
      mockMonthlySummaryGet(body, params, {
        ...options,
        relationshipList: getMockRelationshipList(options)
      })
  },
  [endpoint.merchant.addTsysTerminal]: {
    POST: mockDefaultPostRes
  },
  // MERCHANT PORTAL
  [endpoint.merchant.dashboard]: {
    GET: (body, params, options) => mockMerchantLandingPageGet(body, params, options)
  },
  [endpoint.merchant.authTime]: {
    GET: (body, params, options) => mockMerchantAuthTimeRes(body, params, options)
  },
  [endpoint.merchant.refund]: {
    GET: (body, params, options) => mockMerchantRefundRes(body, params, options)
  },
  [endpoint.merchant.avsUsage]: {
    GET: (body, params, options) => mockMerchantAvsUsageRes(body, params, options)
  },
  [`${endpoint.report.root}/mini`]: {
    GET: (body, params, options) => mockMiniReportGet(body, params, options)
  },
  [endpoint.merchant.submittingPartner]: {
    GET: (body, params, options) => mockSubmittingPartner(body, params, options)
  },
  [endpoint.merchant.delegate]: {
    GET: (body, params, options) => mockMerchantDelegates(body, params, options),
    POST: mockDefaultPostRes,
    DELETE: mockDefaultPostRes
  },
  // CRAB
  [endpoint.crab.v1.file.dummyDownloadUrl]: {
    GET: mockBoardingApplicationDownloadUrlGet
  },
  [endpoint.crab.v1.application.root]: {
    GET: mockPartnerCrabV1ApplicationGet,
    PUT: (body, params, options) => mockPartnerCrabV1ApplicationPut(body, params, options),
    POST: mockDefaultPostRes,
    DELETE: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.signatureType]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.details]: {
    GET: (body, params, options) => mockPartnerCrabV1ApplicationDetailsGet(body, params, options)
  },
  [endpoint.crab.v1.application.mpaDetail]: {
    GET: mockCrabApplicationMpaDetailGet
  },
  [endpoint.crab.v1.application.withdrawRequest]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.requestNewSignature]: {
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.taskPend]: {
    GET: (body, params, options) =>
      mockBoardingApplicationTaskPendGet(body, params, { ...options, userType: 'partner' }),
    POST: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.externalCommunication]: {
    ...sharedStubDataMap[endpoint.crab.v1.application.externalCommunication],
    GET: mockCrabExternalCommunicationGet
  },
  [endpoint.crab.v1.application.taskPendEmailList]: {
    GET: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.template]: {
    GET: (body, params, options) =>
      mockPartnerCrabV1CloneGet(body, params, {
        ...options,
        relationshipList: getMockRelationshipList(options)
      }),
    PUT: mockPartnerCrabV1ClonePut,
    DELETE: mockDefaultPostRes
  },
  [endpoint.crab.v1.application.templateDetail]: {
    GET: (body, params, options) =>
      mockPartnerCrabV1CloneDetailGet(body, params, {
        ...options,
        relationshipList: getMockRelationshipList(options)
      })
  },
  [endpoint.crab.v1.application.mpa]: {
    DELETE: mockDefaultPostRes,
    POST: mockDefaultPostRes,
    PUT: mockPartnerCrabV1ApplicationMpaPut
  },
  [endpoint.crab.v1.application.toMpa]: {
    GET: mockPartnerCrabV1ToMpa
  },
  [endpoint.crab.v1.prevet.root]: {
    GET: mockPartnerPrevetsGet,
    PUT: mockPrevetPut,
    POST: mockDefaultPostRes
  },
  [endpoint.file.v3.root]: {
    ...sharedStubDataMap[endpoint.file.v3.root],
    GET: (body, params, options) =>
      mockFileV3Get(body, params, {
        ...options,
        userType: 'partner'
      })
  },
  [endpoint.custom.settings]: {
    GET: mockCustomSettingsGet,
    POST: mockDefaultPostRes
  }
};
