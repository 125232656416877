import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withRouter from '@f1/shared/src/routing/withRouter';
import { ignoreCase, isEmpty } from '@f1/shared/src/_helpers';
import { Page, PageContent } from '@f1/shared';
import DbaFilterBar from '../components/DbaFilterBar';
import * as actionCreators from '../redux/actions/actionCreators';
import PortfolioReport from './components/reports/PortfolioReport';
import { ReserveDetailsWrapper } from './ReserveDetailsWrapper';

function mapStateToProps(state) {
  return {
    isPartner: state.authenticate.user.isPartner,
    viewAsMerchant: state.filterData.activeFilters.viewAsMerchant,
    currentGuid: state.activeGuid.currentGuid,
    currentDownlines: state.activeDownline.currentDownlines,
    defaultMerchantGuid: state.activeGuid.defaultMerchantGuid,
    masterDownlinesList: state.activeDownline.masterDownlinesList,
    dashboardRequestGuids: state.activeDownline.dashboardRequestGuids,
    // Adding these to pass to new filterbar
    activeFilters: state.filterData.activeFilters,
    activeDownline: state.activeDownline,
    relationshipTree: state.authenticate.user.relationshipTree,
    merchantGuids: state.authenticate.user.merchantGuidToDba,
    filterData: state.filterData,
    activeGuid: state.activeGuid
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export class BoundDashboardReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.updateDashboardGuids();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateDashboardGuids = () => {
    const {
      isPartner,
      viewAsMerchant,
      masterDownlinesList,
      activeDownline,
      currentGuid,
      activeGuid,
      defaultMerchantGuid,
      dashboardRequestGuids
    } = this.props;
    const updateActiveDownline =
      isPartner &&
      !viewAsMerchant &&
      isEmpty(dashboardRequestGuids) &&
      !isEmpty(masterDownlinesList);
    const updateActiveGuid =
      (!isPartner || viewAsMerchant) &&
      (!isEmpty(currentGuid?.guid) ||
        (isEmpty(currentGuid?.guid) && !isEmpty(defaultMerchantGuid)));
    if (updateActiveDownline) {
      activeDownline({
        currentDownlines: masterDownlinesList,
        dashboardRequestGuids: masterDownlinesList.reduce(
          (acc, d) => (d.guid === 'all' ? acc : acc.concat(d.guid)),
          []
        )
      });
    } else if (updateActiveGuid) {
      activeGuid(!isEmpty(currentGuid?.guid) ? currentGuid : defaultMerchantGuid);
    }
  };

  render() {
    const {
      params,
      isPartner,
      viewAsMerchant,
      reportOverride,
      dashboardRequestGuids,
      currentGuid,
      currentDownlines,
      // methods from redux store, used to update store for use on other pages
      activeDownline,
      activeFilters,
      relationshipTree,
      merchantGuids,
      filterData,
      activeGuid
    } = this.props;
    const selectedReport = !isEmpty(reportOverride) ? reportOverride : params?.report || 'sales';
    /* istanbul ignore next */ // TODO BIRB-8404 Get this line covered
    const allInsteadOfList =
      !isEmpty(dashboardRequestGuids) &&
      currentDownlines.some((aRelationship) => ignoreCase(aRelationship.guid) === 'all')
        ? 'all'
        : dashboardRequestGuids;
    return (
      <Page>
        {isEmpty(reportOverride) && (
          <DbaFilterBar
            isPartner={isPartner}
            partnerView={isPartner && !viewAsMerchant}
            // data being used from redux store
            currentGuid={currentGuid}
            currentDownlines={currentDownlines}
            relationshipTree={relationshipTree}
            merchantGuids={merchantGuids}
            // update methods from redux store
            activeGuid={activeGuid}
            activeDownline={activeDownline}
            activeFilters={activeFilters}
            filterData={filterData}
          />
        )}
        <PageContent fullWidth>
          {selectedReport === 'reserve' ? (
            <ReserveDetailsWrapper
              guidValue={
                isPartner && !viewAsMerchant
                  ? { relationshipIdList: allInsteadOfList }
                  : { merchantGuid: currentGuid.guid }
              }
              isPartner={isPartner}
              viewAsMerchant={viewAsMerchant}
              relationshipTree={relationshipTree}
            />
          ) : (
            <PortfolioReport
              reportType={selectedReport}
              guidValue={
                isPartner && !viewAsMerchant ? { guid: dashboardRequestGuids } : currentGuid
              }
              isPartnerView={!!(isPartner && !viewAsMerchant)}
            />
          )}
        </PageContent>
      </Page>
    );
  }
}

BoundDashboardReport.propTypes = {
  params: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  reportOverride: PropTypes.string,
  isPartner: PropTypes.bool,
  masterDownlinesList: PropTypes.oneOfType([PropTypes.array]),
  currentGuid: PropTypes.oneOfType([PropTypes.object]),
  currentDownlines: PropTypes.oneOfType([PropTypes.array]),
  defaultMerchantGuid: PropTypes.oneOfType([PropTypes.object]),
  dashboardRequestGuids: PropTypes.arrayOf(PropTypes.string),
  viewAsMerchant: PropTypes.bool,
  relationshipTree: PropTypes.oneOfType([PropTypes.object]),
  merchantGuids: PropTypes.oneOfType([PropTypes.array]),
  activeFilters: PropTypes.oneOfType([PropTypes.object]),
  // methods from redux store, used to update store for use on other pages
  activeGuid: PropTypes.func,
  activeDownline: PropTypes.func,
  filterData: PropTypes.func
};

BoundDashboardReport.defaultProps = {
  params: {},
  reportOverride: '',
  isPartner: false,
  dashboardRequestGuids: [],
  masterDownlinesList: [],
  currentGuid: {},
  currentDownlines: [],
  defaultMerchantGuid: {},
  viewAsMerchant: false,
  relationshipTree: {},
  merchantGuids: [],
  activeFilters: {},
  // methods from redux store, used to update store for use on other pages
  activeGuid: () => {},
  activeDownline: () => {},
  filterData: () => {}
};

const DashboardReport = connect(mapStateToProps, mapDispatchToProps)(BoundDashboardReport);

export default withRouter(DashboardReport);
