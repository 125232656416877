import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { filterByYearMonth } from '@f1/shared/src/_testHelpers';
import { merchantPortalGuids } from '../guids';

const headers = {
  // defaults
  barGraph: {
    label: 'month',
    lines: [],
    biaxial: ['count'],
    currency: true,
    biaxialCurrency: null
  },
  transactionTable: [
    { order: 1, key: 'merchant.mid', englishName: 'MID' },
    { order: 2, key: 'merchant.legalName', englishName: 'Legal Name' },
    { order: 3, key: 'transactionDateAuth', englishName: 'Transaction Date' },
    { order: 4, key: 'transactionTimestampAuth', englishName: 'Transaction Timestamp' },
    { order: 5, key: 'authorizationResponse', englishName: 'Authorization Response' },
    {
      order: 6,
      key: 'preAuthorizationRequestAmount',
      englishName: 'Preauthorization Request Amount'
    },
    { order: 7, key: 'batchAssociationDate', englishName: 'Batch Association Date' },
    { order: 8, key: 'cardEntryMethod', englishName: 'Card Entry Method' },
    { order: 9, key: 'cashAdvanceAmount', englishName: 'Cash Advance Amount' },
    { order: 10, key: 'avsResponseCode', englishName: 'AVS Response Code' },
    { order: 11, key: 'cvvPresenceIndicator', englishName: 'CVV Presence Indicator' },
    { order: 12, key: 'cvvResponse', englishName: 'CVV Response' },
    { order: 13, key: 'card.cardNumber.cardNumber', englishName: 'Card Number' },
    { order: 14, key: 'card.cardBrand', englishName: 'Card Brand' }
  ]
};

const createMockAuthTableData = (options) => {
  const { length } = options || {};
  const mockAuths = Array.from({ length: length || 26 }).map(() => ({
    txn: {
      transactionDateAuth: getMockValue({ type: 'date' }),
      transactionTimestampAuth: getMockValue({ type: 'isoDate' }),
      authorizationResponse: getMockValue({ type: 'list', list: ['00', '01', '02', '03'] }),
      preAuthorizationRequestAmount: getMockValue({ type: 'price' }),
      batchAssociationDate: getMockValue({ type: 'date' }),
      cardEntryMethod: 'E-Commerce',
      cashAdvanceAmount: getMockValue({ type: 'price' }),
      avsResponseCode: getMockValue({ type: 'number' }),
      cvvPresenceIndicator: getMockValue({ type: 'boolean', stringify: true }),
      cvvResponse: getMockValue({ type: 'number' }),
      card: {
        cardNumber: {
          cardNumberId: getMockValue({ type: 'guid' }),
          cardNumber: getMockValue({ type: 'cardNumber' })
        },
        cardBrand: getMockValue({ type: 'cardBrand' })
      },
      merchant: {
        mid: getMockValue({ type: 'mid' }),
        legalName: getMockValue({ type: 'businessName' })
      }
    }
  }));
  return mockAuths;
};

export const mockReportAuthorizationDataBackend = (options) => {
  const numberProps = { type: 'number', min: 1 };
  const mockResponse = {
    authorizationBarGraph: {
      header: headers.barGraph,
      data: filterByYearMonth(
        [
          { volume: getMockValue(numberProps), month: '2019-08', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2019-09', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2019-10', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2019-11', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2019-12', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-01', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-02', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-03', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-04', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-05', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-06', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-07', count: getMockValue(numberProps) }
        ],
        options
      )
    },
    authorizationTransactionTable: {
      header: headers.transactionTable,
      data: createMockAuthTableData(options)
    }
  };
  return mockResponse;
};

export const mockReportAuthorizationDataFrontend = {
  // FE formatted data by guid
  [`${merchantPortalGuids.merchantGuids.default}`]: {
    data: {
      authorizationBarGraph: {
        header: headers.barGraph,
        data: [
          { volume: 0, month: '2019-08', count: 0 },
          { volume: 0, month: '2019-09', count: 0 },
          { volume: 0, month: '2019-10', count: 0 },
          { volume: 0, month: '2019-11', count: 0 },
          { volume: 0, month: '2019-12', count: 0 },
          { volume: 0, month: '2020-01', count: 0 },
          { volume: 0, month: '2020-02', count: 0 },
          { volume: 0, month: '2020-03', count: 0 },
          { volume: 10374, month: '2020-04', count: 6 },
          { volume: 53599, month: '2020-05', count: 31 },
          { volume: 51870, month: '2020-06', count: 30 },
          { volume: 53599, month: '2020-07', count: 31 }
        ]
      },
      authorizationTransactionTable: {
        header: headers.transactionTable,
        data: [
          {
            authorizationTransactionId: '06fe6b10-7df1-4c3a-9421-fc21c58279bc',
            transactionDateAuth: '2020-07-01',
            transactionTimestampAuth: '2020-08-02T19:35:16.950Z',
            authorizationResponse: '00',
            preAuthorizationRequestAmount: 1729,
            batchAssociationDate: '2020-07-01',
            cardEntryMethod: 'manual',
            cashAdvanceAmount: null,
            avsResponseCode: null,
            cvvPresenceIndicator: null,
            cvvResponse: null,
            mid: 'd32d7de0-4d0f-4a3d-b8ce-76ebfd87f699',
            cardBrand: 'OTHER',
            cardId: 'fbd5359e-e7ad-4634-9881-1744705555b8',
            cardNumber: '033249XXXXXX9566',
            merchantGuid: '84d2dfa3-f22b-43fc-b631-024bbd3c0e53',
            merchantDescriptor: 'PiPACZkwSFWE',
            bankName: 'iWLhHrVTnLnC',
            subPortfolio: 'adNcxOsBVQoN',
            dbaName: 'HOBhusRpqwoE',
            legalName: 'QnIkXmeuBjOS',
            relationshipId: null,
            reservesIndicator: 'OOyeHamiefhX',
            riskLevel: 'iLgU',
            fulfillment: 'qZovlNhrQYOL',
            crm: 'zfQUcLJzhzLu',
            alternateGateway: 'oNpOkWpVfzsO',
            terminalMerchant: 'BOeBKoPunByy',
            chargeBackProvider: 'IOdwYoFIkTqJ',
            productVertical: 'IxYlGvpFxBiP',
            callCenterProvider: 'UfBtCVwcbzWz',
            statusForMerchantInBank: 'RVCybgXmBQRX',
            highRiskRegistration: true,
            publisherOfficeNumber: 'CCqiuxGODVSr',
            publisherName: 'nFgoOQrTIZUe',
            publisherNumber: 'oJYNzDsTRMyw',
            publisherAuthorizationPlatform: 'ssawnGogCyln',
            tin: 'XYSukMFqpSYx',
            servicesSold: 'bAEpRttriKuN',
            businessStartDate: 'EUHoErNZXMFh',
            owner1Id: 'ppxjqRjkJcXi',
            organizationalStructure: 'ktvoneOdpXhI',
            statementDelivery: 'NOySNOeJIZGV',
            daysForDelivery: 'WCZuwMHAQWms',
            applicationId: null,
            optOutAmexMarketingCommunication: false,
            mccCode: null,
            uploadedDocsS3Key: 'yzMlHNnoYlAN',
            typeOfOwnership: 'sjnyaiowcMST',
            previousProcessor: 'kGVeXEcbGqey',
            pastBankruptcy: 'dJQUEybMtA',
            hoursOfOperation: 'MMYTCHDWGOuy',
            pastCardTermination: true,
            tradeSupplierName: 'MgycRhfenuiP',
            tradeProductPurchased: 'mjEwNhLmKXmL',
            tradeSupplierContact: '+15125536624',
            tradeSupplierPhone: '+15125536624',
            merchantAcceptanceDate: '2001-10-24'
          }
        ]
      }
    }
  }
};

export const mockReportAuthorizationGet = (body, params, options) => {
  const { guid = '' } = options || {};
  const backendData = mockReportAuthorizationDataBackend({ ...options, ...params });
  return {
    [guid]: backendData
  };
};
