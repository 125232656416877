// you need to create a reducer for each piece of state in the store.
// reducers take in 2 things.
// 1. the action that happened
import { isEmpty } from '@f1/shared/src/_helpers';

// 2. a copy of current state which to update
export const initialState = {
  user: {
    accessToken: {
      csrfToken: '',
      lastClickTimestamp: 0,
      exp: 0
    },
    portalSignedInAsEmail: '',
    isPartner: false,
    identityToken: {
      email: '',
      emailVerified: false,
      phoneNumber: ''
    },
    topLevelPartner: {},
    merchantGuidToDba: [],
    relationshipTree: {
      subPartner: [],
      merchant: []
    },
    relationshipIdList: [],
    merchantGuidList: [],
    partnerPortalAccessList: [],
    permissions: {},
    merchantDetails: [],
    orphans: {},
    merchantAccessList: [],
    emailVerificationRequired: false,
    resetPassword: false
  },
  isAuthenticated: false
};

function authenticate(state = initialState, action) {
  let isAuth = false;
  switch (action.type) {
    case 'AUTHENTICATE':
      if (typeof action.payload === 'object' && Object.keys(action.payload).length !== 0) {
        // if the user passed authentication
        isAuth = true;
      }
      return {
        ...state,
        ...(action.payload === 'reset'
          ? { ...initialState }
          : {
              isAuthenticated: isAuth,
              user: {
                ...state.user,
                ...(!isEmpty(action.payload) && { ...action.payload })
              }
            })
      };
    case 'RESET_PASSWORD':
      return {
        ...state,
        user: {
          ...state.user,
          resetPassword: action.payload
        }
      };
    default:
      return state;
  }
}
export default authenticate;
