import '@f1/shared/src/wdyr'; // <--- first import
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import swal from 'sweetalert';
import { ErrorBoundary, LoadingPage, ScrollToTop } from '@f1/shared';
import { envIsDevOrLess, endpoint } from '@f1/shared/src/_helpers';
import '@f1/shared/css/global.css';
import Loader from '@f1/shared/src/Loader';
import PrivateRoute from '@f1/shared/src/routing/PrivateRoute';
import PublicRoute from '@f1/shared/src/routing/PublicRoute';
import {
  axiosRequest,
  portalGetAllPageData,
  getCustomSettings,
  getMerchantsWithPermissionList,
  getRelationshipsWithPermissionList,
  saveCustomSettings
} from './utils';
import { portalFeatureKeyMap } from './_customSettingsPortal';
import SiteHeader from './pages/components/SiteHeader';
import Footer from './pages/components/Footer';
import App from './App';

import * as serviceWorker from './serviceWorker';
import store, { persistor } from './redux/store';

import { activeGuid } from './redux/actions/actionCreators';
import DashboardReport from './pages/DashboardReport';

const CustomSettingsDesign = lazy(() => import('./pages/CustomSettingsDesign'));
const StyleGuide = lazy(() => import('./pages/StyleGuide'));
const NotFound = lazy(() => import('@f1/shared/src/pages/404'));
const SignIn = lazy(() => import('./pages/SignIn'));
const SignUp = lazy(() => import('./pages/SignUp'));
const Confirm = lazy(() => import('./pages/Confirm'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Account = lazy(() => import('./pages/Account'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Application = lazy(() => import('./pages/Application'));
const Prevets = lazy(() => import('./pages/Prevets'));
const Faq = lazy(() => import('./pages/Faq'));
const Documents = lazy(() => import('./pages/Documents'));
const Tickets = lazy(() => import('./pages/Tickets'));
const ActionSuggested = lazy(() => import('./pages/ActionSuggested'));
const AchReport = lazy(() => import('@f1/shared/src/pages/ACHReport'));
const ApplicationStatus = lazy(() => import('./pages/ApplicationStatus'));
const Notices = lazy(() => import('./pages/Notices'));
const NoAccess = lazy(() => import('./pages/NoAccess'));
const MerchantDetailsReport = lazy(() => import('./pages/MerchantDetailsReport'));
const MerchantLedgerWrapper = lazy(() => import('./components/MerchantLedgerWrapper'));
const MerchantsOnHold = lazy(() => import('./pages/MerchantsOnHold'));
const MonthlySummaryReport = lazy(() => import('./pages/MonthlySummaryReport'));
const CustomizedReports = lazy(() => import('./pages/CustomizedReports'));
const TrainingWrapper = lazy(() => import('./pages/components/TrainingWrapper'));
const BatchDetailsWrapper = lazy(() => import('./pages/BatchDetailsWrapper'));
const TransactionProfitability = lazy(() => import('./pages/TransactionProfitability'));
const Modal = lazy(() => import('@f1/shared/src/Modal'));
/* istanbul ignore next */ // TODO BIRB-8404 Get this line covered
const PublicWebApplicationForm = lazy(
  () => import('@f1/shared/src/boarding/webform/PublicWebApplicationForm')
);
/* istanbul ignore next */ // TODO BIRB-8404 Get this line covered
const FeatureNotifications = lazy(
  () => import('@f1/shared/src/pages/components/FeatureNotifications')
);

export const checkActiveGuid = () => {
  // if they are already signed in, but refreshed (didn't go through signin page)
  // we need to set the activeGuid)
  const reduxStore = store.getState();
  if (reduxStore.activeGuid.currentGuid && reduxStore.activeGuid.currentGuid.guid === '') {
    if (reduxStore.authenticate.user.identityToken) {
      store.dispatch(activeGuid(reduxStore.authenticate.user.merchantGuidToDba?.[0]));
    }
  }
};

export const Routing = (
  <Provider store={store}>
    <PersistGate loading={<LoadingPage />} persistor={persistor}>
      <ErrorBoundary
        customOptions={{ axiosRequest, userEmail: localStorage.getItem('userEmail') }}
        store={store}>
        <BrowserRouter
          getUserConfirmation={
            /* istanbul ignore next */ (message, callback) => {
              const data = JSON.parse(message);
              const navigate = true;
              if (data.leavingPage) {
                // only trigger if they are leaving the given page(s)
                swal({
                  title: 'WARNING',
                  text: data.message,
                  buttons: ['Cancel', 'Yes, proceed'],
                  dangerMode: true,
                  icon: 'warning',
                  closeOnClickOutside: false,
                  closeOnEsc: false
                }).then((result) => {
                  if (result) {
                    callback(result);
                  }
                });
              } else {
                callback(navigate);
              }
            }
          }>
          <ScrollToTop />
          <SiteHeader />
          <Suspense fallback={<LoadingPage />}>
            <App>
              <Routes>
                {/* PUBLIC */}
                <Route path="*" element={<NotFound />} />
                <Route element={<PublicRoute store={store} />}>
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/confirm" element={<Confirm />} />
                  <Route path="/forgot" element={<ForgotPassword />} />
                  <Route path="/no-access" element={<NoAccess />} />
                  <Route
                    path="/public/web-form"
                    element={<PublicWebApplicationForm axiosRequest={axiosRequest} />}
                  />
                  {envIsDevOrLess() && ( // only reference, do not show in prod
                    <Route path="/designs/custom-settings" element={<CustomSettingsDesign />} />
                  )}
                </Route>
                {/* PRIVATE */}
                <Route
                  element={<PrivateRoute store={store} portal checkActiveGuid={checkActiveGuid} />}>
                  <Route exact path="/" element={<Navigate replace to="/dashboard" />} />
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route exact path="/actionSuggested" element={<ActionSuggested />} />
                  <Route exact path="/merchantDetails" element={<MerchantDetailsReport />} />
                  <Route exact path="/merchantDetails/fees" element={<MerchantDetailsReport />} />
                  <Route
                    exact
                    path="/merchantDetails/neteviaFees"
                    element={<MerchantDetailsReport />}
                  />
                  <Route
                    exact
                    path="/merchantDetails/achFunding"
                    element={<MerchantDetailsReport />}
                  />
                  <Route
                    exact
                    path="/merchantDetails/chargeback"
                    element={<MerchantDetailsReport />}
                  />
                  <Route
                    exact
                    path="/merchantDetails/dispute"
                    element={<MerchantDetailsReport />}
                  />
                  <Route exact path="/merchantDetails/sales" element={<MerchantDetailsReport />} />
                  <Route exact path="/merchantDetails/refund" element={<MerchantDetailsReport />} />
                  <Route
                    exact
                    path="/merchantDetails/reserve"
                    element={<MerchantDetailsReport />}
                  />
                  <Route
                    exact
                    path="/merchantDetails/authorization"
                    element={<MerchantDetailsReport />}
                  />
                  <Route
                    exact
                    path="/merchantDetails/batchDetails"
                    element={<MerchantDetailsReport />}
                  />
                  {/* TODO SNEK-2049: Remove envCheck */}
                  {envIsDevOrLess() && (
                    <Route
                      exact
                      path="/merchantDetails/merchantLedger"
                      element={<MerchantDetailsReport />}
                    />
                  )}
                  <Route
                    exact
                    path="/merchantDetails/documents"
                    element={<MerchantDetailsReport />}
                  />

                  {/* TODO SNEK-2049: Remove envCheck */}
                  {envIsDevOrLess() && (
                    <Route
                      exact
                      path="/report/merchantLedger"
                      element={<MerchantLedgerWrapper />}
                    />
                  )}
                  <Route exact path="/report/merchantsOnHold" element={<MerchantsOnHold />} />
                  <Route exact path="/report/monthlySummary" element={<MonthlySummaryReport />} />
                  <Route exact path="/report/myreports" element={<CustomizedReports />} />
                  <Route
                    path="/achReport"
                    element={
                      <AchReport
                        axiosRequest={axiosRequest}
                        location="portal"
                        store={store}
                        achDetailsEndpoint={endpoint.report.achDetails}
                        achFundingEndpoint={endpoint.report.achFunding}
                        getAllPageData={portalGetAllPageData}
                        getMerchantsWithPermissionList={getMerchantsWithPermissionList}
                        getRelationshipsWithPermissionList={getRelationshipsWithPermissionList}
                      />
                    }
                  />
                  <Route exact path="/reports" element={<DashboardReport />} />
                  <Route
                    exact
                    path="/reports/portfolioBatchReport"
                    element={<BatchDetailsWrapper />}
                  />
                  <Route path="/reports/:report" element={<DashboardReport />} />
                  <Route
                    exact
                    path="/reports/transactionProfitability"
                    element={<TransactionProfitability />}
                  />
                  <Route exact path="/applicationStatus" element={<ApplicationStatus />} />
                  <Route exact path="/notices" element={<Notices />} />
                  <Route exact path="/account" element={<Account />} />
                  <Route exact path="/account/security" element={<Account />} />
                  <Route exact path="/account/delegates" element={<Account />} />
                  <Route exact path="/account/notifications" element={<Account />} />
                  <Route exact path="/application-v2" element={<Application />} />
                  <Route exact path="/prevet" element={<Prevets />} />
                  <Route path="/application" element={<Navigate to="/application-v2" replace />} />
                  <Route exact path="/faq" element={<Faq />} />
                  <Route path="/documents" element={<Documents />} />
                  <Route path="/styleguide" element={<StyleGuide />} />
                  <Route path="/tickets" element={<Tickets />} />
                  <Route exact path="/training" element={<TrainingWrapper location="training" />} />
                  <Route
                    exact
                    path="/training/lesson"
                    element={<TrainingWrapper location="lesson" />}
                  />
                </Route>
              </Routes>
            </App>
            <Modal store={store} />
          </Suspense>
          <Suspense fallback={<></>}>
            <FeatureNotifications
              getCustomSettings={getCustomSettings}
              saveCustomSettings={saveCustomSettings}
              featureKeyMap={portalFeatureKeyMap}
              store={store}
              userLocation="portal"
            />
          </Suspense>
          <Footer />
          <Loader store={store} />
        </BrowserRouter>
      </ErrorBoundary>
    </PersistGate>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root') || document.createElement('div'));
root.render(Routing);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
