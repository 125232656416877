import { getNow, isEmpty } from '@f1/shared/src/_helpers';

export const notificationBellTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      const { config, backendData, hasActionRequiredSettings, hasCrabSettings } = schema || {};
      // action required
      const useNoStarts = config?.actionRequiredReport?.noStarts;
      const useJustTestProcessing = config?.actionRequiredReport?.justTestProcessing;
      const useStoppedProcessing = config?.actionRequiredReport?.stoppedProcessing;
      const useTerminated = config?.actionRequiredReport?.terminated;
      const useRunningHot = config?.actionRequiredReport?.runningHot;
      const useChargebackHealth = config?.actionRequiredReport?.chargebackHealth;
      const useAchReject = config?.actionRequiredReport?.achReject;
      // crab
      const useApproval = config?.crabNotification?.approval;
      const useWithdrawn = config?.crabNotification?.withdrawn;
      const useDecline = config?.crabNotification?.decline;
      const useInitialSubmission = config?.crabNotification?.initialSubmission;
      const usePendsPublication = config?.crabNotification?.pendsPublication;
      const newTotalCount = !isEmpty(backendData)
        ? Object.values(backendData).reduce((acc, value) => acc + (value || 0), 0)
        : null;
      const today = getNow();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      const dataTypeMap = {
        // action required
        achReject: {
          isActionRequired: true,
          title: 'ACH Rejects',
          value: 'achReject',
          href: `/achReport`,
          state: {
            year,
            month,
            type: 'relationship',
            relationship: 'all'
          }
        },
        chargebackHealth: {
          isActionRequired: true,
          title: 'Chargeback Health',
          value: 'chargebackHealth',
          href: `/actionSuggested`
        },
        justTestProcessing: {
          isActionRequired: true,
          title: 'No Real Processing',
          value: 'justTestProcessing',
          href: `/notices#noRealProcessingHash`
        },
        noStarts: {
          isActionRequired: true,
          title: 'No Starts',
          value: 'noStarts',
          href: `/notices#noStartsHash`
        },
        runningHot: {
          isActionRequired: true,
          title: 'Running Hot',
          value: 'runningHot',
          href: `/notices#runningHotHash`
        },
        stoppedProcessing: {
          isActionRequired: true,
          title: 'Stopped Processing',
          value: 'stoppedProcessing',
          href: `/notices#stoppedProcessingHash`
        },
        terminated: {
          isActionRequired: true,
          title: 'Shutdowns',
          value: 'terminated',
          href: `/notices#shutdownsHash`
        },
        // crab
        approval: {
          isCrab: true,
          title: 'Approved',
          value: 'approval',
          href: `/application-v2`,
          state: { status: `approved` } // status to filter on load
        },
        decline: {
          isCrab: true,
          title: 'Declined',
          value: 'decline',
          href: `/application-v2`,
          state: { status: `declined` } // status to filter on load
        },
        initialSubmissions: {
          isCrab: true,
          title: 'Initial Submissions',
          value: 'initialSubmissions',
          href: `/application-v2`,
          state: {} // status to filter on load
        },
        pends: {
          isCrab: true,
          title: 'Pends Publication',
          value: 'pends',
          href: `/application-v2`,
          state: { status: `waiting_on_partner` } // status to filter on load
        },
        withdrawn: {
          isCrab: true,
          title: 'Withdrawn',
          value: 'withdrawn',
          href: `/application-v2`,
          state: { status: `withdrawn` } // status to filter on load
        }
      };
      const formatLink = (type) => [{ count: backendData[type] || 0, ...dataTypeMap[type] }];
      const formattedNotifications = [
        ...(useAchReject ? formatLink('achReject') : []),
        ...(useChargebackHealth ? formatLink('chargebackHealth') : []),
        ...(useJustTestProcessing ? formatLink('justTestProcessing') : []),
        ...(useNoStarts ? formatLink('noStarts') : []),
        ...(useRunningHot ? formatLink('runningHot') : []),
        ...(useStoppedProcessing ? formatLink('stoppedProcessing') : []),
        ...(useTerminated ? formatLink('terminated') : []),
        ...(useApproval ? formatLink('approval') : []),
        ...(useDecline ? formatLink('decline') : []),
        ...(useInitialSubmission ? formatLink('initialSubmissions') : []),
        ...(usePendsPublication ? formatLink('pends') : []),
        ...(useWithdrawn ? formatLink('withdrawn') : [])
      ];
      return {
        hasActionRequiredSettings,
        hasCrabSettings,
        totalCount: newTotalCount,
        notifications: formattedNotifications
      };
    }
    return {};
  }
};

export default notificationBellTemplate;
