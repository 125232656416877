import { buildFormComponents } from '@f1/shared/src/_formHelpers';
import { getLocaleString } from '@f1/shared/src/_helpers';

export const notificationConfigTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      return {
        actionRequiredReport: {
          actionRequiredReportList: formatActionRequiredReport(schema),
          reportFrequency: schema?.actionRequiredReport?.reportFrequency,
          otherFields: {
            lastReportSendDate: {
              label: 'Last Report Sent',
              text: getLocaleString(schema?.actionRequiredReport?.lastReportSendDate, {
                includeTime: false
              })
            }
          }
        },
        crabNotification: {
          crabNotificationList: formatCrabNotifications(schema, { type: 'array' }),
          otherFields: {
            lastReportSendTimestamp: {
              label: 'Last Report Sent',
              text: getLocaleString(schema?.crabNotification?.lastReportSendTimestamp)
            }
          }
        }
      };
    }
    return schema;
  },
  frontendBellConfig: (schema, version) => {
    if (version === '1.0') {
      return {
        actionRequiredReport: formatActionRequiredReport(schema),
        crabNotification: formatCrabNotifications(schema)
      };
    }
    return {};
  },
  frontendFormFields: (schema, version) => {
    if (version === '1.0') {
      const { formFields, formData } = schema;
      const convertBeData = notificationConfigTemplate.frontend(formData, version);
      const initilizedFields = Object.keys(formFields)?.reduce((acc, fieldName) => {
        acc[fieldName] = buildFormComponents(convertBeData[fieldName], formFields[fieldName]);
        return acc;
      }, {});
      return initilizedFields;
    }
    return [];
  },
  backend: (schema, version) => {
    if (version === '1.0') {
      const { actionRequiredReportList, crabNotificationList, reportFrequency } = schema;
      const actionRequiredReport = {
        reportsActive: actionRequiredReportList?.reportsActive || false,
        noStarts: actionRequiredReportList?.noStarts || false,
        justTestProcessing: actionRequiredReportList?.justTestProcessing || false,
        stoppedProcessing: actionRequiredReportList?.stoppedProcessing || false,
        terminated: actionRequiredReportList?.terminated || false,
        runningHot: actionRequiredReportList?.runningHot || false,
        chargebackHealth: actionRequiredReportList?.chargebackHealth || false,
        achReject: actionRequiredReportList?.achReject || false,
        reportFrequency
      };
      const crabNotification = {
        reportsActive: crabNotificationList?.crabReportsActive || false,
        approval: crabNotificationList?.approval || false,
        withdrawn: crabNotificationList?.withdrawn || false,
        decline: crabNotificationList?.decline || false,
        pendsPublication: crabNotificationList?.pendsPublication || false,
        initialSubmission: crabNotificationList?.initialSubmission || false
      };
      return { actionRequiredReport, crabNotification };
    }
    return schema;
  }
};

const formatActionRequiredReport = (backendData) => ({
  reportsActive: backendData?.actionRequiredReport?.reportsActive,
  noStarts: backendData?.actionRequiredReport?.noStarts,
  justTestProcessing: backendData?.actionRequiredReport?.justTestProcessing,
  stoppedProcessing: backendData?.actionRequiredReport?.stoppedProcessing,
  terminated: backendData?.actionRequiredReport?.terminated,
  runningHot: backendData?.actionRequiredReport?.runningHot,
  chargebackHealth: backendData?.actionRequiredReport?.chargebackHealth,
  achReject: backendData?.actionRequiredReport?.achReject
});

const formatCrabNotifications = (backendData, options) => {
  const { type } = options || {};
  const { crabNotification } = backendData || {};
  const formattedObject = {
    crabReportsActive: crabNotification?.reportsActive,
    approval: crabNotification?.approval,
    withdrawn: crabNotification?.withdrawn,
    decline: crabNotification?.decline,
    initialSubmission: crabNotification?.initialSubmission,
    pendsPublication: crabNotification?.pendsPublication
  };
  return type === 'array'
    ? Object.entries(formattedObject).map(([key, value]) => ({ [key]: value }))
    : formattedObject;
};

export default notificationConfigTemplate;
