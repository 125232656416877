import React from 'react';
import { buildDataTable, isEmpty, monthsArray, chartHeader } from '@f1/shared/src/_helpers';

// BIRB-8338 covered by CustomReportsWidget Tests
/* istanbul ignore next */
const reportDataTemplate = {
  toCustomReport: (schema) => {
    const { data = {}, report = {} } = schema;
    const {
      [`${report?.baseReport}BarGraph`]: barGraph = data || {},
      [`${report?.baseReport}TransactionTable`]: transactions = {}
    } = data || {};
    const barGraphObj = getDataObj(barGraph);
    const { data: graphData, header: cHeader } = barGraphObj || {};
    const txnObject = getDataObj(transactions);
    const { header: txnHeader, data: txnData } = txnObject || {};
    const formattedTableData = buildDataTable(
      !isEmpty(txnHeader) ? txnHeader : [],
      !isEmpty(txnData) ? formatTxns(txnData) : []
    );
    const hiddenColumns = formattedTableData?.hiddenColumns ?? [];
    const allColumns = formattedTableData?.header?.columnOrder ?? [];
    const selectedColumns = report?.tableColumns ?? [];
    allColumns.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenColumns.push(column);
      }
    });
    const newData = {
      chartHeader: cHeader ?? {},
      tableHeader: formattedTableData?.header,
      chartData: !isEmpty(graphData) ? barGraphObj : {},
      tableData: formattedTableData?.data,
      tableHiddenColumns: formattedTableData?.hiddenColumns
    };
    return newData;
  },
  toAuthorizationData: (schema) => {
    // convert /report/authorization data for table and graph use.
    if (!isEmpty(schema)) {
      const tableData = schema.authorizationTransactionTable;
      const tableDataFormatted = buildDataTable(
        !isEmpty(tableData.header) ? tableData.header : [],
        !isEmpty(tableData.data) ? formatTxns(tableData.data) : []
      );
      const newData = {
        chartData: {
          header: { ...chartHeader, ...schema.authorizationBarGraph.header },
          data: schema.authorizationBarGraph.data
        },
        tableData: tableDataFormatted.data.map((row) => formatForLinks(row)),
        headers: tableDataFormatted.header,
        hiddenColumns: tableDataFormatted.hiddenColumns
      };
      return newData;
    }
    return schema;
  },
  toDisputeData: (schema) => {
    if (!isEmpty(schema)) {
      const tableData = schema.disputeTransactionTable;
      const tableDataFormatted = buildDataTable(
        !isEmpty(tableData.header) ? tableData.header : [],
        !isEmpty(tableData.data) ? formatTxns(tableData.data) : []
      );
      const newData = {
        chartData: {
          header: {
            ...chartHeader,
            ...(schema.disputeBarGraph?.header || schema.chargebackBarGraph.header)
          },
          data: schema.disputeBarGraph?.data || schema.chargebackBarGraph.data
        },
        tableData: tableDataFormatted.data.map((row) => formatForLinks(row)),
        headers: tableDataFormatted.header,
        hiddenColumns: tableDataFormatted.hiddenColumns
      };
      return newData;
    }
    return schema;
  },
  toRefundData: (schema) => {
    if (!isEmpty(schema)) {
      const tableData = schema.refundTransactionTable;
      const tableDataFormatted = buildDataTable(
        !isEmpty(tableData.header) ? tableData.header : [],
        !isEmpty(tableData.data) ? formatTxns(tableData.data) : []
      );
      const newData = {
        chartData: {
          header: { ...chartHeader, ...schema.refundBarGraph.header },
          data: schema.refundBarGraph.data
        },
        tableData: tableDataFormatted.data.map((row) => formatForLinks(row)),
        headers: tableDataFormatted.header,
        hiddenColumns: tableDataFormatted.hiddenColumns
      };
      return newData;
    }
    return schema;
  },
  toSalesData: (schema) => {
    if (!isEmpty(schema)) {
      const tableData = schema.salesTransactionTable;
      const tableDataFormatted = buildDataTable(
        !isEmpty(tableData.header) ? tableData.header : [],
        !isEmpty(tableData.data) ? formatTxns(tableData.data) : []
      );
      const newData = {
        chartData: {
          header: { ...chartHeader, ...schema.salesBarGraph.header },
          data: schema.salesBarGraph.data
        },
        tableData: tableDataFormatted.data.map((row) => formatForLinks(row)),
        headers: tableDataFormatted.header,
        hiddenColumns: tableDataFormatted.hiddenColumns
      };
      return newData;
    }
    return schema;
  },
  toChargebackData: (schema) => {
    if (!isEmpty(schema)) {
      const tableData = schema.chargebackTransactionTable;
      const tableDataFormatted = buildDataTable(
        !isEmpty(tableData.header) ? tableData.header : [],
        !isEmpty(tableData.data) ? formatTxns(tableData.data) : []
      );
      const newData = {
        chartData: {
          header: { ...chartHeader, ...schema.chargebackBarGraph.header },
          data: schema.chargebackBarGraph.data
        },
        tableData: tableDataFormatted.data.map((row) => formatForLinks(row)),
        headers: tableDataFormatted.header,
        hiddenColumns: tableDataFormatted.hiddenColumns
      };
      return newData;
    }
    return schema;
  },
  toPortalRefundData: (schema) =>
    isEmpty(schema)
      ? schema
      : {
          refundChart: {
            header: {
              ...chartHeader,
              negatives: true,
              stacked: true,
              ...schema.header
            },
            data: schema.data
          },
          refund: formatDates(schema.data.map((row) => formatForLinks(row)))
        },
  toPartnerLandingPageData: (schema) =>
    isEmpty(schema)
      ? schema
      : {
          authorization: formatDates(schema.volumeCount.authorization),
          authorizationChart: {
            data: schema.volumeCount.authorization,
            header: {
              ...chartHeader,
              stacked: true,
              showLegend: true,
              ...schema.volumeCount.authorizationChart.header
            }
          },
          chargeback: formatDates(schema.volumeCount.chargeback),
          chargebackChart: {
            data: schema.volumeCount.chargeback,
            header: {
              ...chartHeader,
              stacked: true,
              showLegend: true,
              ...schema.volumeCount.chargebackChart.header
            }
          },
          settlement: formatDates(schema.volumeCount.settlement),
          settlementChart: {
            data: schema.volumeCount.settlement,
            header: {
              ...chartHeader,
              stacked: true,
              showLegend: true,
              ...schema.volumeCount.settlementChart.header
            }
          }
        },
  toMerchantLandingPageData: (schema) =>
    isEmpty(schema)
      ? schema
      : {
          // we don't have table data for merchants, just show the graph data in table
          authorization: schema.authorizationBarChart.data,
          authorizationChart: {
            header: {
              ...chartHeader,
              stacked: false,
              showLegend: false,
              label: 'month',
              biaxial: ['count'],
              lines: ['count'],
              ...schema.authorizationBarChart.header
            },
            data: schema.authorizationBarChart.data
          },
          chargeback: schema.chargebackBarChart.data,
          chargebackChart: {
            header: {
              ...chartHeader,
              stacked: false,
              showLegend: false,
              label: 'month',
              biaxial: ['count'],
              lines: ['count'],
              ...schema.chargebackBarChart.header
            },
            data: schema.chargebackBarChart.data
          },
          merchantTransactionSummaries: schema.merchantTransactionSummaries,
          sales: schema.salesBarChart.data,
          salesChart: {
            header: {
              ...chartHeader,
              stacked: false,
              showLegend: false,
              label: 'month',
              biaxial: ['count'],
              lines: ['count'],
              ...schema.salesBarChart.header
            },
            data: schema.salesBarChart.data
          }
        },
  toReportDataTable: (schema) => {
    if (!isEmpty(schema)) {
      return schema?.map((item) => {
        const monthNumber = monthsArray?.indexOf(item?.date) + 1;
        return {
          ...item,
          ...(item?.date
            ? {
                date: {
                  title: monthNumber,
                  originalData: monthNumber,
                  tableDownloadValue: item.date,
                  key: item.date
                }
              }
            : {}),
          ...(item?.month ? { month: item?.month } : {}),
          ...(item?.volume ? { volume: item?.volume } : {})
        };
      });
    }
    return schema;
  }
};

const formatDates = (data) =>
  data?.map((item) => {
    const monthNumber = monthsArray?.indexOf(item?.date) + 1;
    return {
      ...item,
      ...(item?.date
        ? {
            date: {
              title: monthNumber,
              originalData: monthNumber,
              tableDownloadValue: item.date,
              key: item.date
            }
          }
        : {})
    };
  });

// BIRB-8338 covered by CustomReportsWidget Tests
/* istanbul ignore next */
const getDataObj = (data) => {
  /**
   * api data could come in 2 different formats (array or object)
   * example:
   * authorizationBarGraph: [{ data: [], header: {} }]
   * authorizationBarGraph: { data: [], header: {} }
   *
   * example:
   * chargebackTransactionTable: [{ data: [], header: {} }]
   * chargebackTransactionTable: { data: [], header: {} }
   */
  const dataObject = Array.isArray(data) ? data[0] || {} : data || {};
  return dataObject;
};

const formatTxns = (data) => {
  const formatted = data.map((row) => {
    const { txn } = row || {};
    const entries = flatten(txn);
    return entries;
  });
  return formatted;
};

const flatten = (obj, roots = [], sep = '.') =>
  Object.keys(obj).reduce(
    (memo, prop) =>
      Object.assign(
        {},
        memo,
        Object.prototype.toString.call(obj[prop]) === '[object Object]'
          ? flatten(obj[prop], roots.concat([prop]), sep)
          : { [roots.concat([prop]).join(sep)]: obj[prop] }
      ),
    {}
  );

const formatForLinks = (obj) => {
  const row = { ...obj };
  Object.keys(row).forEach((prop) => {
    if (prop === 'mid' || prop === 'merchant.mid') {
      row[prop] = <a href={`/merchantDetails?mid=${row[prop]}`}>{row[prop]}</a>;
    }
    if (prop === 'batchIdFt') {
      const dateMatch = (row?.transactionDate || '').substring(0, 7);
      row.batchIdFt = (
        <a
          href={`/merchantDetails/batchDetails?batchId=${row[prop]}&to=${dateMatch}&from=${dateMatch}`}>
          {row[prop]}
        </a>
      );
    }
  });
  return row;
};

export default reportDataTemplate;
