import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { filterByYearMonth } from '@f1/shared/src/_testHelpers';
import { merchantPortalGuids } from '../guids';

const headers = {
  // defaults
  barGraph: {
    label: 'month',
    lines: [],
    biaxial: ['count'],
    currency: true,
    biaxialCurrency: null
  },
  transactionTable: [
    { order: 1, key: 'merchant.mid', englishName: 'MID' },
    { order: 2, key: 'merchant.legalName', englishName: 'Legal Name' },
    { order: 3, key: 'batchIdFt', englishName: 'Batch ID' },
    { order: 4, key: 'purchaseAmount', englishName: 'Purchase Amount' },
    { order: 5, key: 'transactionDate', englishName: 'Transaction Date' },
    { order: 6, key: 'authorizationCodeSt', englishName: 'Authorization Code' },
    { order: 7, key: 'authorizationAmount', englishName: 'Authorization Amount' },
    { order: 8, key: 'status', englishName: 'Status' },
    { order: 9, key: 'achDate', englishName: 'ACH Date' },
    { order: 10, key: 'achTimestamp', englishName: 'ACH Timestamp' },
    { order: 11, key: 'associationDate', englishName: 'Association Date' },
    { order: 12, key: 'card.cardBrand', englishName: 'Card Brand' },
    { order: 13, key: 'card.cardNumber.cardNumber', englishName: 'Card Number' },
    { order: 14, key: 'transactionType', englishName: 'Transaction Type' },
    { order: 15, key: 'cardEntryMethod', englishName: 'Card Entry Method' },
    { order: 16, key: 'avsResponseCode', englishName: 'AVS Response Code' }
  ]
};

const createMockSalesTableData = (options) => {
  const { length } = options || {};
  const mockSalesData = Array.from({ length: length || 26 }).map(() => ({
    txn: {
      purchaseAmount: getMockValue({ type: 'price' }),
      authorizationCodeSt: getMockValue({ type: 'list', list: ['00', '01', '02', '03'] }),
      authorizationAmount: getMockValue({ type: 'price' }),
      transactionDate: getMockValue({ type: 'date' }),
      status: 'Paid',
      achDate: getMockValue({ type: 'date' }),
      achTimestamp: getMockValue({ type: 'isoDate' }),
      associationDate: getMockValue({ type: 'date' }),
      transactionType: 'Capture',
      cardEntryMethod: 'E-Commerce',
      avsResponseCode: getMockValue({ type: 'list', list: ['04', '05', '06', '07'] }),
      batchIdFt: getMockValue({ min: 10000000, max: 99999999, stringify: true }),
      card: {
        cardNumber: {
          cardNumberId: getMockValue({ type: 'guid' }),
          cardNumber: getMockValue({ type: 'cardNumber' })
        },
        cardBrand: getMockValue({ type: 'cardBrand' })
      },
      merchant: {
        mid: getMockValue({ type: 'mid' }),
        legalName: getMockValue({ type: 'businessName' })
      }
    }
  }));
  return mockSalesData;
};

export const mockReportSalesDataBackend = (options) => {
  const numberProps = { type: 'number', min: 1 };
  const mockResponse = {
    salesBarGraph: {
      header: headers.barGraph,
      data: filterByYearMonth(
        [
          { volume: getMockValue(numberProps), month: '2019-08', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2019-09', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2019-10', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2019-11', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2019-12', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-01', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-02', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-03', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-04', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-05', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-06', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-07', count: getMockValue(numberProps) },
          { volume: getMockValue(numberProps), month: '2020-08', count: getMockValue(numberProps) }
        ],
        options
      )
    },
    salesTransactionTable: {
      header: headers.transactionTable,
      data: createMockSalesTableData(options)
    }
  };
  return mockResponse;
};

export const mockReportSalesDataFrontend = {
  // FE formatted data by guid
  [`${merchantPortalGuids.merchantGuids.default}`]: {
    data: {
      salesBarGraph: {
        header: headers.barGraph,
        data: [
          { volume: 0, month: '2019-08', count: 0 },
          { volume: 0, month: '2019-09', count: 0 },
          { volume: 0, month: '2019-10', count: 0 },
          { volume: 0, month: '2019-11', count: 0 },
          { volume: 0, month: '2019-12', count: 0 },
          { volume: 0, month: '2020-01', count: 0 },
          { volume: 0, month: '2020-02', count: 0 },
          { volume: 0, month: '2020-03', count: 0 },
          { volume: 20748, month: '2020-04', count: 6 },
          { volume: 107198, month: '2020-05', count: 31 },
          { volume: 103740, month: '2020-06', count: 30 },
          { volume: 107198, month: '2020-07', count: 31 },
          { volume: 6916, month: '2020-08', count: 2 }
        ]
      },
      salesTransactionTable: {
        header: headers.transactionTable,
        data: [
          {
            salesTransactionId: '01df86e5-9b20-4b82-998e-725fe8a3c76e',
            purchaseAmount: -1729,
            authorizationCodeSt: 'ZuWphGYDtmzg',
            authorizationAmount: -1729,
            transactionDate: '2020-07-29',
            status: 'XzWwgNDbqFOb',
            achDate: '2020-07-29',
            achTimestamp: '2020-08-02T19:35:16.950Z',
            associationDate: '2020-07-29',
            transactionType: 'vxnFSvRQLIvq',
            cardEntryMethod: 'manual',
            avsResponseCode: 'UieUIzUMAFGP',
            batchIdFt: 'laYxXuYXpAvy',
            mid: 'd32d7de0-4d0f-4a3d-b8ce-76ebfd87f699',
            'merchant.mid': 'd32d7de0-4d0f-4a3d-b8ce-76ebfd87f699',
            cardBrand: 'MASTERCARD',
            cardId: 'bce8b0ae-9c78-469c-9981-fa218003797a',
            cardNumber: '103800XXXXXX5171',
            merchantGuid: '84d2dfa3-f22b-43fc-b631-024bbd3c0e53',
            merchantDescriptor: 'PiPACZkwSFWE',
            bankName: 'iWLhHrVTnLnC',
            subPortfolio: 'adNcxOsBVQoN',
            dbaName: 'HOBhusRpqwoE',
            legalName: 'QnIkXmeuBjOS',
            relationshipId: null,
            reservesIndicator: 'OOyeHamiefhX',
            riskLevel: 'iLgU',
            fulfillment: 'qZovlNhrQYOL',
            crm: 'zfQUcLJzhzLu',
            alternateGateway: 'oNpOkWpVfzsO',
            terminalMerchant: 'BOeBKoPunByy',
            chargeBackProvider: 'IOdwYoFIkTqJ',
            productVertical: 'IxYlGvpFxBiP',
            callCenterProvider: 'UfBtCVwcbzWz',
            statusForMerchantInBank: 'RVCybgXmBQRX',
            highRiskRegistration: true,
            publisherOfficeNumber: 'CCqiuxGODVSr',
            publisherName: 'nFgoOQrTIZUe',
            publisherNumber: 'oJYNzDsTRMyw',
            publisherAuthorizationPlatform: 'ssawnGogCyln',
            tin: 'XYSukMFqpSYx',
            servicesSold: 'bAEpRttriKuN',
            businessStartDate: 'EUHoErNZXMFh',
            owner1Id: 'ppxjqRjkJcXi',
            organizationalStructure: 'ktvoneOdpXhI',
            statementDelivery: 'NOySNOeJIZGV',
            daysForDelivery: 'WCZuwMHAQWms',
            applicationId: null,
            optOutAmexMarketingCommunication: false,
            mccCode: null,
            uploadedDocsS3Key: 'yzMlHNnoYlAN',
            typeOfOwnership: 'sjnyaiowcMST',
            previousProcessor: 'kGVeXEcbGqey',
            pastBankruptcy: 'dJQUEybMtA',
            hoursOfOperation: 'MMYTCHDWGOuy',
            pastCardTermination: true,
            tradeSupplierName: 'MgycRhfenuiP',
            tradeProductPurchased: 'mjEwNhLmKXmL',
            tradeSupplierContact: '+15125536624',
            tradeSupplierPhone: '+15125536624',
            merchantAcceptanceDate: '2001-10-24'
          }
        ]
      }
    }
  }
};

export const mockReportSalesGet = (body, params, options) => {
  const { guid = '' } = options || {};
  const backendData = mockReportSalesDataBackend({ ...options, ...params });
  return {
    [guid]: backendData
  };
};
