import { transformData } from '@f1/shared/src/_helpers';
import cbCalculatorTemplate from './templates/cbCalculatorTemplate';

export const mockCbCalculatorRes = (body, params, options) => {
  // api response
  const { guid = '' } = options || {};
  return {
    [guid]: {
      averageTicket: {
        dataType: 'dollar',
        value: 50
      },
      monthlyVolume: {
        dataType: 'dollar',
        value: 50000
      },
      discover: {
        cbCount: {
          dataType: 'number',
          value: 5
        },
        cbRatio: {
          dataType: 'ratio',
          value: 0.005
        },
        sales: {
          dataType: 'ratio',
          value: 0.05
        }
      },
      masterCard: {
        cbCount: {
          dataType: 'number',
          value: 35
        },
        cbRatio: {
          dataType: 'ratio',
          value: 0.0075
        },
        sales: {
          dataType: 'ratio',
          value: 0.35
        }
      },
      visa: {
        cbCount: {
          dataType: 'number',
          value: 70
        },
        cbRatio: {
          dataType: 'ratio',
          value: 0.01
        },
        sales: {
          dataType: 'ratio',
          value: 0.6
        }
      }
    }
  };
};

export const mockCbCalculatorFormatted = transformData({
  data: mockCbCalculatorRes({}, {}, { guid: 'testGuid' }).testGuid,
  toSchema: 'frontend',
  template: cbCalculatorTemplate,
  version: '1.0'
});

export default mockCbCalculatorRes;
