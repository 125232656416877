export const initialState = {
  notices: [],
  count: 0
};

function notifications(state = initialState, action) {
  switch (action.type) {
    case 'NOTIFICATIONS':
      return {
        ...state,
        notices: action.notices,
        count: action.notices.length
      };
    default:
      return state;
  }
}

export default notifications;
