import {
  buildFeesAndRates,
  buildNeteviaFields
} from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationJson';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { partnerPortalGuids } from '../guids';

export const mockStaticMerchantVar = {
  // Mock data used for var endpoint
  default: [
    { varCreationTimestamp: '2020-04-01T14:40:16.950' },
    { varCreationTimestamp: '2020-05-01T14:40:16.950' }
  ]
};

export const mockMerchantDetailGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: mockMerchantDetailsDataBackend(options)
  };
};

const aPerson = () => {
  const mockUser = getMockValue({ type: 'user' });
  const mockAddress1 = getMockValue({ type: 'address' });
  return {
    firstName: mockUser.firstName,
    lastName: mockUser.lastName,
    dob: getMockValue({ type: 'date' }),
    maskedSsn: getMockValue({ type: 'ssn', isMasked: true }),
    ownershipPercentage: getMockValue({ type: 'ratio' }),
    homeStreetAddress: mockAddress1.fullAddress,
    city: mockAddress1.cityName,
    state: mockAddress1.stateCode,
    zip: mockAddress1.zipCode,
    emailAddress: mockUser.email,
    businessPhoneNumber: getMockValue({ type: 'bePhone' }),
    mobileNumber: getMockValue({ type: 'bePhone' }),
    county: mockAddress1.county,
    type: getMockValue({ type: 'list', list: ['Primary', 'Beneficiary'] }),
    title: mockUser.jobTitle,
    fullName: mockUser.fullName
  };
};
const mockMerchantDetailsDataBackend = (options) => {
  const { relationshipList = [], guid } = options || {};
  const mockDescriptor = getMockValue({ type: 'description' });
  const mockContact = getMockValue({ type: 'user' });
  const mockAddress = getMockValue({ type: 'address' });
  const closedMerchantList = ['ae352237-a435-4d37-9c38-7c5db510f4fc'];
  const mockStatus = closedMerchantList.includes(guid)
    ? getMockValue({ type: 'list', list: ['Closed', 'Soft Close'] })
    : 'Open';
  const mockRelationship = relationshipList[0];
  const { relationshipCode, riskProfile, title, value } = mockRelationship || {};
  const mockIsSeasonalBusiness = getMockValue({ type: 'boolean' });
  const staticMidMap = {
    // guid to hardcoded mid value (FTs)
    '83d67c25-f49e-4a06-8bea-fd4022ebad40': '123456789012', // partner portal
    '84d2dfa3-f22b-43fc-b631-024bbd3c0e53': '11111111110' // merchant portal
  };
  const defaultProcessorGuids = [
    // mock data/FTs
    'bcb1c3ba-097a-4519-be7a-a1de9c1a2cf1' // merchant portal
  ];
  const repayNonStaticGuids = [
    // Repay guids that generate random repay fees data
    'a7d0f1e1-834a-42ea-93a8-710deebcb7fc' // partner portal (dev) FE test - repay/elevated - 4/11
  ];
  const repayGuids = [
    // mock data/FTs
    ...repayNonStaticGuids,
    partnerPortalGuids.merchantGuids.default, // partner portal
    'fc10b25f-bbcd-441c-b670-07e28a63d9e7', // partner portal
    '47bda678-010d-4277-9447-516641a1ff23', // partner portal
    'ef1c8d72-cdb8-4af3-8f78-af091f0f7869', // merchant portal
    'mock-repay-merchant-guid-2',
    'mock-repay-merchant-guid-1',
    'mock-repay-merchant-guid-inactive'
  ];
  const neteviaGuids = [
    partnerPortalGuids.merchantGuids.merchant03,
    partnerPortalGuids.merchantGuids.merchant04
  ];
  const priorityTsysGuids = [
    '22c288f3-0a99-4a97-8755-795811923915',
    '43334724-d030-40f2-bd63-ed83b53d0e54',
    '84d2dfa3-f22b-43fc-b631-024bbd3c0e53' // merchant portal
  ];
  const priorityFirstDataGuids = ['cc7d8717-aa0d-4757-8fd0-1fb4a140c9d1'];
  const notBoardedViaCrabGuids = [
    partnerPortalGuids.merchantGuids.secondWithData // partner portal
  ];
  const generateRepayFees = repayGuids.includes(guid);
  const generateNeteviaFees = neteviaGuids.includes(guid);
  const generatePriorityTsysFees = priorityTsysGuids.includes(guid);
  const generatePriorityFirstDataFees = priorityFirstDataGuids.includes(guid);
  const merchantNotInCrab = notBoardedViaCrabGuids.includes(guid);
  const generatedRatesAndFees = {
    ...(generateRepayFees && {
      repayFields: {
        ratesAndFees: buildFeesAndRates({ mpaIndex: repayNonStaticGuids.includes(guid) ? 0 : 1 })
      }
    }),
    ...(generateNeteviaFees && {
      neteviaFields: {
        ratesAndFees: buildFeesAndRates({ mpaIndex: 1, processName: 'netevia' }),
        fees: buildNeteviaFields(1).fees
      }
    }),
    ...(generatePriorityTsysFees && {
      priorityFields: {
        tsysRatesAndFees: {
          ratesAndFees: buildFeesAndRates({
            mpaIndex: 1,
            processName: 'priority',
            bankName: 'synovus'
          })
        }
      }
    }),
    ...(generatePriorityFirstDataFees && {
      priorityFields: {
        firstDataRatesAndFees: {
          ratesAndFees: buildFeesAndRates({
            mpaIndex: 1,
            processName: 'priority',
            bankName: 'wells_fargo'
          })
        }
      }
    })
  };
  const useDebitOnly = priorityTsysGuids.includes(guid);
  const useChargebackOnly = repayGuids.includes(guid);
  const mockBillingAddress = getMockValue({ type: 'address' });
  return {
    merchant: {
      achInfo: {
        bankContact: getMockValue({ type: 'boolean' }) ? mockContact.fullName : null,
        bankPhone: getMockValue({ type: 'boolean' }) ? getMockValue({ type: 'bePhone' }) : null,
        dateOpened: getMockValue({ type: 'boolean' }) ? getMockValue({ type: 'date' }) : null,
        ...createMockAchDetails(),
        ...(useDebitOnly && { debitOnly: createMockAchDetails() }),
        ...(useChargebackOnly && { chargebackOnly: createMockAchDetails() })
      },
      general: {
        mid: staticMidMap[guid] || getMockValue({ type: 'mid' }),
        discoverMid: '000000000000001',
        descriptor: mockDescriptor,
        descriptorShort: getMockValue({ type: 'boolean' }) ? mockDescriptor.slice(0, 22) : null,
        dbaName: getMockValue({ type: 'businessName' }),
        legalName: getMockValue({ type: 'businessName' }),
        application: merchantNotInCrab
          ? null
          : {
              // applicationId more or less should match crab GET /application applicationId mock data
              applicationId: getMockValue({ min: 1, max: 15, stringify: true }),
              applicationBusinessNumber: getMockValue({ min: 1, max: 3000, stringify: true })
            },
        relationship: {
          relationshipId: value,
          relationshipCode,
          relationshipName: title
        },
        reservesIndicator: getMockValue({ type: 'list', list: ['yes', 'no', null] }),
        maskedEin: getMockValue({ type: 'ssn', isMasked: true }),
        mccCode: getMockValue({ type: 'mcc' }),
        crm: getMockValue({ type: 'list', list: [null, 'CorCRM', 'Konnektive', 'Limitless CRM'] }),
        chargebackProvider: getMockValue({
          type: 'list',
          list: [null, 'Pending', 'Midigator LLC', 'Chargebacks 911']
        }),
        chargebackNotificationEmailAddress: generateRepayFees
          ? getMockValue({ type: 'email', userType: 'external' })
          : null,
        fulfillment: getMockValue({
          type: 'list',
          list: [null, 'Affiliate Fulfillment LLC', 'Alchemy Nutra', 'Amware Fulfillment']
        }),
        callCenterProvider: getMockValue({
          type: 'list',
          list: [null, 'ASK Telemarketing', 'Aegus Group', 'Alpha Connect']
        }),
        amexServiceEstablishmentNumber: getMockValue({ type: 'boolean' })
          ? getMockValue({ min: 10000, max: 9999999, stringify: true })
          : null
      },
      contactInfo: {
        address: mockAddress.fullAddress,
        address2: getMockValue({ type: 'list', list: [null, 'Apt 2B', 'Suite 321'] }),
        city: mockAddress.cityName,
        state: mockAddress.stateCode,
        zip: mockAddress.zipCode,
        country: 'US',
        contactEmail: mockContact.email,
        contactPhone: getMockValue({ type: 'bePhone' }),
        contactName: mockContact.fullName,
        url: getMockValue({ type: 'boolean' }) ? getMockValue({ type: 'url' }) : null,
        addressType: getMockValue({
          type: 'list',
          list: [null, 'Mailing', 'Location', 'Physical', 'Billing']
        }),
        faxNumber: getMockValue({ type: 'boolean' }) ? getMockValue({ type: 'bePhone' }) : null,
        billingAddress: {
          addressLine1: mockBillingAddress.fullAddress,
          addressLine2: getMockValue({ type: 'list', list: ['Apt 6A', 'Suite 987'] }),
          city: mockBillingAddress.cityName,
          state: mockBillingAddress.stateCode,
          postalCode: mockBillingAddress.zipCode,
          country: 'US'
        }
      },
      paymentSummary: {
        seasonalBusiness: mockIsSeasonalBusiness,
        averageTransactionAmount: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'price' })
          : null,
        highestTransactionAmount: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'price' })
          : null,
        currentAllowedMonthlyVolume: getMockValue({ type: 'number' }),
        salesMethod: {
          cardsSwiped: 0.5,
          telephoneOrder: 0.4,
          ecommerce: 0.1
        },
        seasonalBusinessJan: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessFeb: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessMar: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessApr: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessMay: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessJun: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessJul: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessAug: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessSep: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessOct: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessNov: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        seasonalBusinessDec: mockIsSeasonalBusiness ? getMockValue({ type: 'boolean' }) : null,
        fundingChoices: getMockValue({
          type: 'list',
          list: [null, 'standard', 'next_day_funding']
        }),
        visaCard: getMockValue({ type: 'boolean' }),
        masterCard: getMockValue({ type: 'boolean' }),
        discoverCard: getMockValue({ type: 'boolean' }),
        amexOptBlue: getMockValue({ type: 'boolean' }),
        debitCardsOnly: getMockValue({ type: 'boolean' }),
        otherCard: getMockValue({ type: 'boolean' }),
        masterCardNobRegStatus: getMockValue({ type: 'list', list: [null, 'open', 'closed'] }),
        guarantorSignedDate: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'date' })
          : null,
        amexAcceptance: getMockValue({ type: 'boolean' }) ? getMockValue({ type: 'date' }) : null,
        annualVolume: getMockValue({ type: 'boolean' }) ? getMockValue({ type: 'number' }) : null,
        imprintWithCardPresent: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'number' })
          : null,
        currentHoldbackRatio: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'ratio' })
          : null,
        currentHoldbackPeriod: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'number', stringify: true })
          : null,
        hrBillingModel:
          riskProfile === 'elevated'
            ? getMockValue({ type: 'list', list: ['Straight Sale', 'SS Continuity', 'Trial'] })
            : null,
        hrBusinessType:
          riskProfile === 'elevated'
            ? getMockValue({ type: 'list', list: ['Nutra', 'Non Nutra'] })
            : null,
        hrBusinessSubtype1:
          riskProfile === 'elevated'
            ? getMockValue({
                type: 'list',
                list: [
                  'BizOps',
                  'Convenient Store',
                  'Gadgets',
                  'Health Consulting',
                  'Hydro Equipment',
                  'Misc. Ingestible',
                  'Misc. Services',
                  'Multiple SubTypes',
                  'Muscle',
                  'No Limit Zone',
                  'Nutra',
                  'Physical Non-Nutra',
                  'Plus Choice Wellness ProWeight Loss',
                  'Restaurant',
                  'Savings Club',
                  'Skin Serum',
                  'Topical',
                  'Weight Loss',
                  'Wellness'
                ]
              })
            : null,
        hrBusinessSubtype2:
          riskProfile === 'elevated'
            ? getMockValue({ type: 'list', list: ['Apple', 'Banana', 'Carrot'] })
            : null,
        reserveHoldbackRatio: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'ratio' })
          : null,
        reserveHoldbackDays: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'number', stringify: true })
          : null,
        averageMonthlyVolume: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'number' })
          : null,
        suppressStatement: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'boolean' })
          : null,
        combinedAch: getMockValue({ type: 'boolean' }),
        discountType: getMockValue({ type: 'list', list: ['monthly', 'daily'] }),
        electronicBenefitsTransfer: true,
        fnsEbtNumber: getMockValue({ type: 'accountNumber' }),
        netAch: true
      },
      status: {
        status: mockStatus,
        approvedDate: getMockValue({ type: 'date' }),
        closedDate: mockStatus !== 'Open' ? getMockValue({ type: 'date' }) : null,
        closureReason: mockStatus !== 'Open' ? getMockValue({ type: 'description' }) : null,
        partnerClosureMessage:
          mockStatus !== 'Open'
            ? `${getMockValue({ type: 'description', length: 'long' })}\n${getMockValue({ type: 'description', length: 'long' })}`
            : null
      },
      person: Array.from({ length: getMockValue({ min: 1, max: 4 }) }).map(() => aPerson()),
      ...(!defaultProcessorGuids.includes(guid) && {
        fees: {
          ...generatedRatesAndFees
        }
      })
    }
  };
};

const createMockAchDetails = () => ({
  nameOnAccount: getMockValue({ type: 'user', userType: 'external' }).fullName,
  nameOfBank: getMockValue({ type: 'bankName' }),
  maskedAccountNumber: getMockValue({ type: 'accountNumber', isMasked: true }),
  maskedRoutingNumber: getMockValue({ type: 'routingNumber', isMasked: true })
});

export default mockMerchantDetailGet;
