import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AlertBar, SiteNav, Header } from '@f1/shared';
import { endpoint, isCorvia, isEmpty, isPublicUrl } from '@f1/shared/src/_helpers';
import * as actionCreators from '../../redux/actions/actionCreators';
import { axiosRequest, getLinks, getMyNotifications } from '../../utils';
import withPolling from '../../components/WithPolling';
import { DataBoxNotificationBell } from '../../components/DataBoxNotificationBell';

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authenticate.isAuthenticated,
    user: state.authenticate.user,
    barStyle: state.alertBar.barStyle,
    message: state.alertBar.message,
    timeout: state.alertBar.timeout,
    merchantData: state.dataUpdate.merchantData,
    pageName: state.updatePage.pageName,
    boardingEnabled: state.appState.boardingEnabled,
    viewAsMerchant: state.filterData.activeFilters.viewAsMerchant,
    permissions: state.authenticate.user.permissions
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export class BoundSiteHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      links: [],
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true,
      hideBellTimestamp: 0,
      notificationsLoading: true,
      notificationData: {}
    };
  }

  componentDidMount() {
    const { serverMaintenance } = this.props;
    this.mounted = true;
    if (serverMaintenance) {
      // only check an api call if there's an existing server maintenance issue
      this.checkApiStatus();
    }
    this.handleNavigation();
  }

  componentDidUpdate(prevProps) {
    const {
      barStyle,
      message,
      timeout,
      location,
      isAuthenticated,
      serverMaintenance,
      user: { isPartner },
      boardingEnabled,
      viewAsMerchant
    } = this.props;
    if (prevProps.barStyle !== barStyle || prevProps.message !== message) {
      this.updateState({
        alertBarType: barStyle,
        alertBarMessage: message,
        alertBarTimeout: timeout
      });
    }
    if (
      (isAuthenticated && prevProps.isAuthenticated !== isAuthenticated) ||
      prevProps.location.pathname !== location.pathname ||
      (isPartner && prevProps.boardingEnabled !== boardingEnabled && boardingEnabled) ||
      prevProps.viewAsMerchant !== viewAsMerchant
    ) {
      if (serverMaintenance) {
        this.checkApiStatus();
      }
      this.handleNavigation();
    }
    if (prevProps.serverMaintenance !== serverMaintenance) {
      this.checkApiStatus();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  };

  handleCloseAlertBar = (data) => {
    const { alertBar } = this.props;
    const { alertBarType, alertBarMessage, alertBarTimeout } = data;
    alertBar(alertBarType, alertBarMessage, alertBarTimeout);
  };

  checkApiStatus = async () => {
    const { appState, serverMaintenance } = this.props;
    // call captcha endpoint to validate no 423 (server maintenance) issue
    const apiRes = await axiosRequest({
      url: endpoint.parameter.captchaSiteKey,
      method: 'get',
      tokenRequired: false
    });
    if (apiRes?.data) {
      if (serverMaintenance) {
        await appState({ serverMaintenance: false });
        window.location.reload();
      }
    }
  };

  handleNavigation = async () => {
    const { isAuthenticated, user, authenticate, updatePage } = this.props;
    if (isAuthenticated) {
      authenticate(user);
    }
    updatePage({
      pageName: window.location.pathname.substring(1) || 'home'
    });
    this.setHeaderLinks();
  };

  handleSignOut = async (e) => {
    const { navigate, resetStore } = this.props;
    e && e.preventDefault();
    await resetStore();
    navigate('/signin', {
      state: {
        clearPolling: true
      }
    });
  };

  setHeaderLinks = async () => {
    const { isAuthenticated, viewAsMerchant } = this.props;
    let portalLinks;
    const headerLinks = await getLinks('header', viewAsMerchant);
    const formatted = !isEmpty(headerLinks)
      ? headerLinks.reduce(
          (acc, link) =>
            isEmpty(link)
              ? acc
              : acc.concat({
                  ...link,
                  ...(link.text === 'Account' &&
                    !isEmpty(link.submenu) && {
                      submenu: link.submenu.map((submenuLink) => ({
                        ...submenuLink,
                        ...(submenuLink.text === 'Sign Out' && { click: this.handleSignOut })
                      }))
                    })
                }),
          []
        )
      : [];
    if (isAuthenticated) {
      portalLinks = !isEmpty(formatted) ? [...formatted] : [];
    }
    this.updateState(
      {
        links: isAuthenticated ? portalLinks : []
      },
      isAuthenticated ? this.getNotificationCounts : null
    );
  };

  getNotificationCounts = async (options) => {
    const { user } = this.props;
    const { isPartner } = user || {};
    if (isPartner) {
      const { hardRefresh } = options || {};
      const { notificationData } = this.state;
      const newNotificationData =
        hardRefresh || isEmpty(notificationData) ? await getMyNotifications() : notificationData;
      this.updateState({
        notificationsLoading: false,
        notificationData: newNotificationData
      });
    }
  };

  getDestination = () => {
    const { permissions } = this.props;
    const { hasDashboardAccess } = permissions;
    if (isCorvia()) {
      return hasDashboardAccess ? '/dashboard' : '/merchantDetails';
    }
    return '/';
  };

  hardRefresh = () => {
    this.updateState(
      {
        notificationsLoading: true
      },
      () => this.getNotificationCounts({ hardRefresh: true })
    );
  };

  render() {
    const {
      alertBarType,
      alertBarMessage,
      alertBarTimeout,
      hideBellTimestamp,
      notificationsLoading,
      notificationData,
      mouseX,
      links
    } = this.state;
    const { barStyle, isAuthenticated, resetStore, user } = this.props;
    const destination = this.getDestination();
    return isPublicUrl ? null : (
      <Header destination={destination}>
        <SiteNav
          headerLinks={links}
          isAuthenticated={isAuthenticated}
          resetStore={resetStore}
          signedInAs={user?.portalSignedInAsEmail}
          refreshNotificationCallback={this.hardRefresh}
          notificationData={notificationData}
          notificationsLoading={notificationsLoading}
          mouseXCallback={this.updateState}
          hideBellTimestamp={hideBellTimestamp}
          customNotificationComponent={
            <DataBoxNotificationBell
              closeCallback={this.updateState}
              loading={notificationsLoading}
              mouseX={mouseX}
              notificationData={notificationData}
              refreshCallback={this.hardRefresh}
            />
          }
        />
        {barStyle !== 'closed' && (
          <AlertBar
            options={{ barStyle: alertBarType, message: alertBarMessage, timeout: alertBarTimeout }}
            callback={this.handleCloseAlertBar}
          />
        )}
      </Header>
    );
  }
}

BoundSiteHeader.propTypes = {
  isAuthenticated: PropTypes.bool,
  alertBar: PropTypes.func,
  barStyle: PropTypes.string,
  message: PropTypes.string,
  timeout: PropTypes.bool,
  resetStore: PropTypes.func,
  user: PropTypes.shape({
    isPartner: PropTypes.bool,
    portalSignedInAsEmail: PropTypes.string
  }),
  authenticate: PropTypes.func,
  updatePage: PropTypes.func,
  appState: PropTypes.func,
  navigate: PropTypes.func,
  serverMaintenance: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  merchantData: PropTypes.shape({
    miniReport: PropTypes.shape({
      authorization: PropTypes.oneOfType([PropTypes.object]),
      chargeback: PropTypes.oneOfType([PropTypes.object]),
      sales: PropTypes.oneOfType([PropTypes.object]),
      refund: PropTypes.oneOfType([PropTypes.object])
    })
  }),
  boardingEnabled: PropTypes.bool,
  viewAsMerchant: PropTypes.bool,
  permissions: PropTypes.shape({
    hasDashboardAccess: PropTypes.bool
  })
};

BoundSiteHeader.defaultProps = {
  isAuthenticated: false,
  alertBar: () => {},
  barStyle: 'closed',
  message: '',
  timeout: true,
  resetStore: () => {},
  user: {
    isPartner: false,
    portalSignedInAsEmail: ''
  },
  authenticate: () => {},
  updatePage: () => {},
  appState: () => {},
  navigate: () => {},
  serverMaintenance: false,
  location: {
    pathname: '/'
  },
  merchantData: {
    miniReport: {
      authorization: {},
      chargeback: {},
      sales: {},
      refund: {}
    }
  },
  boardingEnabled: false,
  viewAsMerchant: false,
  permissions: {
    hasDashboardAccess: true
  }
};

const SiteHeader = withPolling(connect(mapStateToProps, mapDispatchToProps)(BoundSiteHeader));

export default SiteHeader;
