import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router';
import { isPublicUrl } from '../_helpers';

export default function PublicRoute({ store = false, redirect = null }) {
  const { serverMaintenance } = store.getState().appState;
  if (serverMaintenance) {
    /* istanbul ignore next */
    return <Navigate to="/serverMaintenance" replace />;
  }
  if (redirect) {
    /* istanbul ignore next */
    return <Navigate to={redirect} replace />;
  }
  const { isAuthenticated } = store.getState().authenticate;
  return isAuthenticated && !isPublicUrl ? <Navigate to="/" replace /> : <Outlet />;
}

PublicRoute.propTypes = {
  store: PropTypes.oneOfType([PropTypes.any]),
  redirect: PropTypes.string
};
