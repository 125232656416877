import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { mockBoardingApplicationJson } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationJson';
import { getElevatedRelationships } from '@f1/shared/src/_templateHelpers';
import { isEmpty } from '@f1/shared/src/_helpers';
import {
  mockPreferredRelationships,
  mockElevatedRelationships
} from './mockPartnerCrabV1Application';
import { mockRelationships } from '../_mockDataHelpers';

const createMockTemplates = (options) => {
  const { relationshipList = [], isFuncTest } = options || {};
  const mockNeteviaElevatedRelationship =
    (relationshipList || []).find(
      (r) => r.relationshipCode === mockElevatedRelationships.netevia.relationshipCode
    ) || {};
  const mockRepayPreferredRelationship =
    (relationshipList || []).find((r) => {
      const { relationshipCode } = isFuncTest
        ? mockPreferredRelationships.funcTest
        : mockRelationships.relationship10;
      return r.relationshipCode === relationshipCode;
    }) || {};
  const mockTemplates = [
    {
      applicationTemplateName: 'Mock Template - Netevia/Elevated',
      applicationTemplateId: 'mock-netevia-elevated-clone-id',
      relationship: {
        relationshipId: mockNeteviaElevatedRelationship?.value,
        relationshipName: mockNeteviaElevatedRelationship?.title,
        relationshipCode: mockNeteviaElevatedRelationship?.relationshipCode
      }
    },
    {
      applicationTemplateName: 'Mock Template - Repay/Preferred',
      applicationTemplateId: 'mock-repay-preferred-clone-id',
      relationship: {
        relationshipId: mockRepayPreferredRelationship?.value,
        relationshipName: mockRepayPreferredRelationship?.title,
        relationshipCode: mockRepayPreferredRelationship?.relationshipCode
      }
    }
  ];
  return mockTemplates;
};

export const mockPartnerCrabV1CloneGet = (body, params, options) => {
  const { guid = [`"{}"`] } = options || {};
  return {
    [guid]: {
      templates: createMockTemplates(options)
    }
  };
};

export const mockPartnerCrabV1ClonePut = (body, params, options) => {
  const { guid = [`"{}"`] } = options || {};
  return {
    [guid]: {
      applicationTemplateId: 'mock-clone-id'
    }
  };
};

export const mockPartnerCrabV1CloneDetailGet = (body, params, options) => {
  const { guid = '', relationshipList = [] } = options || {};
  const mockTemplates = createMockTemplates(options);
  const mockTemplateMatch = mockTemplates.find((t) => t.applicationTemplateId === guid);
  const elevatedRelationships = getElevatedRelationships(relationshipList);
  const randomRelationshipId = getMockValue({ type: 'list', list: elevatedRelationships });
  const mockRandomRelationship = relationshipList.find((r) => r.value === randomRelationshipId);
  return {
    [guid]: {
      applicationTemplateName: getMockValue({ type: 'string' }),
      applicationTemplateMpa: mockBoardingApplicationJson('template', {
        relationshipId: randomRelationshipId
      }),
      applicationTemplateId: guid,
      relationship: {
        relationshipId: randomRelationshipId,
        relationshipName: mockRandomRelationship?.title,
        relationshipCode: mockRandomRelationship?.relationshipCode
      },
      ...(!isEmpty(mockTemplateMatch) && { ...mockTemplateMatch })
    }
  };
};
