import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import withRouter from '@f1/shared/src/routing/withRouter';

import * as actionCreators from '../../redux/actions/actionCreators';
import { footerCSS } from './_styles';

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authenticate.isAuthenticated,
    filterData: state.filterData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export class BoundFooterLink extends React.Component {
  handleClick = (e) => {
    const { isAuthenticated, filterData } = this.props;
    if (isAuthenticated) {
      const { currentTarget } = e;
      if (currentTarget.href.toLowerCase().includes('signout')) {
        this.signOut(e);
        return;
      }
      if (currentTarget.className.includes('pLink')) {
        filterData({ pageNum: 1 });
      }
    }
  };

  signOut = (e) => {
    const { navigate } = this.props;
    e && e.preventDefault();
    navigate('/signin', {
      state: {
        clearPolling: true
      }
    });
  };

  render() {
    const { link, type } = this.props;
    return (
      <li className="link" style={footerCSS.li} key={link.text}>
        <Link
          className={`${link.text.replace('/', '_').toLowerCase()}_${type} ${type}`}
          style={{ color: 'white' }}
          to={link.href}
          onClick={link.click ? link.click : (e) => this.handleClick(e)}>
          {link.text}
        </Link>
      </li>
    );
  }
}

BoundFooterLink.propTypes = {
  type: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.object]),
  isAuthenticated: PropTypes.bool,
  filterData: PropTypes.func,
  navigate: PropTypes.func,
  resetStore: PropTypes.func
};

BoundFooterLink.defaultProps = {
  type: 'sLink',
  link: {},
  isAuthenticated: false,
  filterData: () => {},
  navigate: () => {},
  resetStore: () => {}
};
const FooterLink = connect(mapStateToProps, mapDispatchToProps)(BoundFooterLink);

export default withRouter(FooterLink);
