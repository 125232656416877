import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

const createMockAuthTimeData = () => {
  const mockTimeRanges = [
    '12AM - 1AM',
    '1AM - 2AM',
    '2AM - 3AM',
    '3AM - 4AM',
    '4AM - 5AM',
    '5AM - 6AM',
    '6AM - 7AM',
    '7AM - 8AM',
    '8AM - 9AM',
    '9AM - 10AM',
    '10AM - 11AM',
    '11AM - 12PM',
    '12PM - 1PM',
    '1PM - 2PM',
    '2PM - 3PM',
    '3PM - 4PM',
    '4PM - 5PM',
    '5PM - 6PM',
    '6PM - 7PM',
    '7PM - 8PM',
    '8PM - 9PM',
    '9PM - 10PM',
    '10PM - 11PM',
    '11PM - 12AM'
  ];
  const mockData = mockTimeRanges.map((rangeLabel) => ({
    label: rangeLabel,
    count: getMockValue({ min: 1, max: 30 })
  }));
  return mockData;
};

export const mockMerchantAuthTimeRes = (body, params, options) => {
  const { guid = 'testGuid' } = options;
  return {
    [guid]: {
      monthToDate: {
        header: {
          label: 'label',
          lines: ['count'],
          biaxial: [],
          currency: false,
          biaxialCurrency: null
        },
        data: createMockAuthTimeData(options)
      },
      pastThreeMonths: {
        header: {
          label: 'label',
          lines: ['count'],
          biaxial: [],
          currency: false,
          biaxialCurrency: null
        },
        data: createMockAuthTimeData(options)
      },
      pastSixMonths: {
        header: {
          label: 'label',
          lines: ['count'],
          biaxial: [],
          currency: false,
          biaxialCurrency: null
        },
        data: createMockAuthTimeData(options)
      }
    }
  };
};

export default mockMerchantAuthTimeRes;
