import globalFormProps from '@f1/shared/src/_formHelpers';

export const merchantDelegatePermissions = [
  {
    title: 'Dashboard Access',
    value: 'dashboardAccess',
    hasAccessKey: 'hasDashboardAccess',
    tooltip: `User can view the landing page.`
  },
  {
    title: 'Delegate Access',
    value: 'delegateAccess',
    hasAccessKey: 'hasDelegateAccess',
    tooltip: `User can view all delegates for the selected MID, create new delegates, and delete any existing delegates.`
  },
  {
    title: 'Document Access',
    value: 'documentAccess',
    hasAccessKey: 'hasDocumentAccess',
    tooltip: `User can view and use the Documents page.`
  },
  {
    title: 'Financial Access',
    value: 'financialAccess',
    hasAccessKey: 'hasFinancialAccess',
    tooltip: `User can view both the Residual and Merchant Ledger pages.`
  },
  {
    title: 'Report Access',
    value: 'reportAccess',
    hasAccessKey: 'hasReportAccess',
    tooltip: `User can view any of the Report pages.`
  }
];

export const partnerDelegatePermissions = [
  {
    title: 'Dashboard Access',
    value: 'dashboardAccess',
    hasAccessKey: 'hasDashboardAccess',
    tooltip: `User can view the partner portal landing page.`
  },
  {
    title: 'Report Access',
    value: 'reportAccess',
    hasAccessKey: 'hasReportAccess',
    tooltip: `User can view report data on all Merchant Report pages.`
  },
  {
    title: 'Residual Access',
    value: 'residualAccess',
    hasAccessKey: 'hasResidualAccess',
    tooltip: `User can view the Income graph for all top-level relationships on the partner portal landing page.`
  },
  {
    title: 'Delegate Access',
    value: 'delegateAccess',
    hasAccessKey: 'hasDelegateAccess',
    tooltip: `User can view all delegates for the selected relationship, create new delegates, and delete any existing delegates.`
  },
  {
    title: 'Application Access',
    value: 'applicationAccess',
    hasAccessKey: 'hasApplicationAccess',
    tooltip: `User can view all completed and in-progress merchant applications, and create/submit new merchant applications to the bank.`
  },
  {
    title: 'Document Access',
    value: 'documentAccess',
    hasAccessKey: 'hasDocumentAccess',
    tooltip: `User can view all documents for the selected relationships on the Documents page.`
  },
  {
    title: 'VAR Creation Access',
    value: 'varCreationAccess',
    hasAccessKey: 'hasVarCreationAccess',
    tooltip: 'User can create new/additional Terminal VARs'
  },
  {
    title: 'Maintenance Access',
    value: 'maintenanceAccess',
    hasAccessKey: 'hasMaintenanceAccess',
    tooltip: 'User can modify merchant fields on the portal directly'
  }
];

export const delegateEditFormFields = [
  {
    componentType: 'checkboxList',
    props: {
      ...globalFormProps.checkboxList,
      label: 'Set Permissions',
      id: 'permissions',
      required: true,
      list: [], // List gets rendered within component
      wrapperStyle: {
        width: '100%',
        border: '1px solid lightgrey',
        fontSize: '0.9em',
        lineHeight: '1.8em'
      },
      innerWrapperStyle: {
        border: 'none',
        padding: '0.167em 0.5em 0.25em 0.5em',
        overflowY: 'auto',
        width: '100%'
      }
    }
  }
];

export const addPartnerDelegateFields = partnerDelegatePermissions.reduce(
  (acc, item) => ({
    ...acc,
    [item.value]: {
      checked: false,
      disabled: false,
      title: item.title,
      tooltip: item.tooltip
    }
  }),
  {}
);

export const addMerchantDelegateFields = merchantDelegatePermissions.reduce(
  (acc, item) => ({
    ...acc,
    [item.value]: {
      checked: false,
      disabled: false,
      title: item.title,
      tooltip: item.tooltip
    }
  }),
  {}
);

export default delegateEditFormFields;
