import { portalPageKeys, portalSettingsStructure } from '../../_customSettingsPortal';

const mockCustomReports = [
  // My Reports page
  {
    baseReport: 'sales',
    baseReportTitle: 'Sales',
    dateRange: 'mtd',
    guidType: 'merchantGuid',
    guidValue: '938e4a42-c848-41c4-a2eb-747b31a8dde0',
    selectedRelationshipList: [],
    reportName: 'Mock Sales - MTD - Table (Merchant)',
    convertedReportName: 'Mock-Sales---MTD---Table-(Merchant)',
    reportType: 'table',
    position: 1,
    tableColumns: [
      'merchant.mid',
      'merchant.legalName',
      'batchIdFt',
      'purchaseAmount',
      'transactionDate',
      'authorizationCodeSt',
      'authorizationAmount',
      'status',
      'achDate',
      'achTimestamp',
      'associationDate',
      'card.cardBrand',
      'card.cardNumber.cardNumber',
      'transactionType',
      'cardEntryMethod',
      'avsResponseCode'
    ],
    selectedTableColumnList: [
      { value: 'merchant.mid', title: 'MID' },
      { value: 'merchant.legalName', title: 'Legal Name' },
      { value: 'batchIdFt', title: 'Batch ID' },
      { value: 'purchaseAmount', title: 'Purchase Amount' },
      { value: 'transactionDate', title: 'Transaction Date' },
      { value: 'authorizationCodeSt', title: 'Authorization Code' },
      { value: 'authorizationAmount', title: 'Authorization Amount' },
      { value: 'status', title: 'Status' },
      { value: 'achDate', title: 'ACH Date' },
      { value: 'achTimestamp', title: 'ACH Timestamp' },
      { value: 'associationDate', title: 'Association Date' },
      { value: 'card.cardBrand', title: 'Card Brand' },
      { value: 'card.cardNumber.cardNumber', title: 'Card Number' },
      { value: 'transactionType', title: 'Transaction Type' },
      { value: 'cardEntryMethod', title: 'Card Entry Method' },
      { value: 'avsResponseCode', title: 'AVS Response Code' }
    ]
  },
  {
    baseReport: 'sales',
    baseReportTitle: 'Sales',
    dateRange: 'ytd',
    guidType: 'merchantGuid',
    guidValue: '77f302f2-5d4f-4742-8b63-7307429a12ba',
    selectedRelationshipList: [],
    reportName: 'Mock Sales - YTD - Graph (Merchant)',
    convertedReportName: 'Mock-Sales---YTD---Graph-(Merchant)',
    reportType: 'graph',
    position: 2
  }
];

export const mockCustomSettings = {
  ...portalSettingsStructure,
  [portalPageKeys.globalPreferences]: {
    ...portalSettingsStructure[portalPageKeys.globalPreferences],
    acknowledgedHasMaintenanceAccess: true
  },
  [portalPageKeys.customReportsPage]: {
    ...portalSettingsStructure[portalPageKeys.customReportsPage],
    customData: mockCustomReports
  }
};

export const mockCustomSettingsGet = (_body, _params, _options) => {
  const mockData = { userSettingsPayload: JSON.stringify(mockCustomSettings) };
  return mockData;
};

export default mockCustomSettingsGet;
