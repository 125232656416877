import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

const createMockAuthBarChart = (options) => {
  const { guid } = options || {};
  const staticMap = {
    // For FTs
    'fc10b25f-bbcd-441c-b670-07e28a63d9e7': 10000, // CTUH_ADD_1_closed-0
    '071d33c0-5801-4b05-b699-af3863b9d853': 5000 // CTUH_ADD_1_closed-1
  };
  const mockData = {
    header: {
      label: 'month',
      lines: ['count'],
      biaxial: ['count'],
      currency: true,
      biaxialCurrency: false
    },
    data: [
      {
        volume: staticMap[guid] || getMockValue({ type: 'number', max: 60000 }),
        month: '2020-02',
        count: getMockValue({ type: 'number' })
      },
      {
        volume: staticMap[guid] || getMockValue({ type: 'number', max: 60000 }),
        month: '2020-03',
        count: getMockValue({ type: 'number' })
      },
      {
        volume: staticMap[guid] || getMockValue({ type: 'number', max: 60000 }),
        month: '2020-04',
        count: getMockValue({ type: 'number' })
      },
      {
        volume: staticMap[guid] || getMockValue({ type: 'number', max: 60000 }),
        month: '2020-05',
        count: getMockValue({ type: 'number' })
      },
      {
        volume: staticMap[guid] || getMockValue({ type: 'number', max: 60000 }),
        month: '2020-06',
        count: getMockValue({ type: 'number' })
      },
      {
        volume: staticMap[guid] || getMockValue({ type: 'number', max: 60000 }),
        month: '2020-07',
        count: getMockValue({ type: 'number' })
      },
      {
        volume: staticMap[guid] || getMockValue({ type: 'number', max: 60000 }),
        month: '2020-08',
        count: getMockValue({ type: 'number' })
      }
    ]
  };
  return mockData;
};

export const mockMerchantLandingPageDataBackend = (options) => {
  // BE api response by guid
  const mockRes = {
    salesBarChart: {
      header: {
        label: 'month',
        lines: ['count'],
        biaxial: ['count'],
        currency: true,
        biaxialCurrency: false
      },
      data: [
        { volume: 0, month: '2020-02', count: 0 },
        { volume: 0, month: '2020-03', count: 0 },
        { volume: 20748, month: '2020-04', count: 6 },
        { volume: 107198, month: '2020-05', count: 31 },
        { volume: 103740, month: '2020-06', count: 30 },
        { volume: 107198, month: '2020-07', count: 31 },
        { volume: 6916, month: '2020-08', count: 2 }
      ]
    },
    chargebackBarChart: {
      header: {
        label: 'date',
        lines: ['ratio'],
        biaxial: ['ratio'],
        currency: false,
        biaxialCurrency: false
      },
      data: [
        {
          date: 'Feb',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.0
        },
        {
          date: 'Mar',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.0
        },
        {
          date: 'Apr',
          discover: 1,
          other: 1,
          americanExpress: 1,
          visa: 1,
          dinerClub: 2,
          mastercard: 0,
          ratio: 1.0
        },
        {
          date: 'May',
          discover: 7,
          other: 8,
          americanExpress: 4,
          visa: 5,
          dinerClub: 4,
          mastercard: 3,
          ratio: 1.0
        },
        {
          date: 'Jun',
          discover: 3,
          other: 6,
          americanExpress: 6,
          visa: 4,
          dinerClub: 3,
          mastercard: 8,
          ratio: 1.0
        },
        {
          date: 'Jul',
          discover: 3,
          other: 3,
          americanExpress: 11,
          visa: 6,
          dinerClub: 5,
          mastercard: 3,
          ratio: 1.0
        },
        {
          date: 'Aug',
          discover: 1,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 1,
          mastercard: 0,
          ratio: 1.0
        }
      ]
    },
    authorizationBarChart: createMockAuthBarChart(options),
    merchantTransactionSummaries: {
      authorizationCount: 100,
      authorizationVolume: 172900.0,
      settlementCount: 100,
      settlementVolume: 345800.0,
      chargebackCount: 100,
      chargebackVolume: 172900.0,
      chargebackRatio: 100.0
    }
  };
  return mockRes;
};

export const mockMerchantLandingPageGet = (body, params, options) => {
  const { guid = '' } = options || {};
  const backendMockData = mockMerchantLandingPageDataBackend(options);
  return { [guid]: backendMockData };
};
