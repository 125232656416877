import { dashboardGuids } from '../guids';

const mockData = {
  all: [
    { month: 'Feb', CTUH_ADD_2: 1200.0, CTUH_ADD_1: 600.0 },
    { month: 'Mar', CTUH_ADD_2: 1000.0, CTUH_ADD_1: 500.0 },
    { month: 'Apr', CTUH_ADD_2: 800.0, CTUH_ADD_1: 400.0 },
    { month: 'May', CTUH_ADD_2: 600.0, CTUH_ADD_1: 300.0 },
    { month: 'Jun', CTUH_ADD_2: 400.0, CTUH_ADD_1: 200.0 },
    { month: 'Jul', CTUH_ADD_2: 200.0, CTUH_ADD_1: 100.0 },
    { month: 'Aug', CTUH_ADD_2: 0.0, CTUH_ADD_1: 0.0 }
  ],
  CTUH_ADD_1: [
    { month: 'Feb', CTUH_ADD_1: 600.0 },
    { month: 'Mar', CTUH_ADD_1: 500.0 },
    { month: 'Apr', CTUH_ADD_1: 400.0 },
    { month: 'May', CTUH_ADD_1: 300.0 },
    { month: 'Jun', CTUH_ADD_1: 200.0 },
    { month: 'Jul', CTUH_ADD_1: 100.0 },
    { month: 'Aug', CTUH_ADD_1: 0.0 }
  ]
};

const mockDataByGuid = {
  [`["${dashboardGuids.partnerPortal.guid01}","${dashboardGuids.partnerPortal.guid02}"]`]: [
    ...mockData.all
  ],
  [`["${dashboardGuids.partnerPortal.guid03}","${dashboardGuids.partnerPortal.guid04}"]`]: [
    ...mockData.all
  ],
  [`["${dashboardGuids.partnerPortal.guid01}"]`]: [...mockData.CTUH_ADD_1],
  [`["${dashboardGuids.partnerPortal.guid03}"]`]: [...mockData.CTUH_ADD_1]
};

export const mockPartnerIncomeGet = (body, params, options) => {
  const { guid = [`"{}"`] } = options || {};
  const backendData = mockDataByGuid[guid] || Object.values(mockDataByGuid)[0]; // default to first set of mock data if guid doesn't exist
  return {
    [guid]: backendData
  };
};

export default mockPartnerIncomeGet;
