export const merchantPortalGuids = {
  merchantGuids: {
    default: '84d2dfa3-f22b-43fc-b631-024bbd3c0e53',
    secondWithData: '7b154a14-2f97-4657-987e-ed5c5ae9b833'
  },
  partnerGuids: {},
  relationshipGuids: {}
};

export const dashboardGuids = {
  partnerPortal: {
    guid01: 'd289c5d3-7195-49a3-9fa6-1a8ef04056e6', // CTUH_ADD_1 for FT (preferred risk)
    guid02: 'c7f99ba1-ab36-4d0a-a638-ea80e0564d84', // CTUH_ADD_2 for FT (elevated risk)
    guid03: '75b37aec-35e7-4dc0-89e2-09de11852e5b', // CTUH_ADD_1 in dev (elevated risk)
    guid04: '1b222b4f-73b6-4b7c-bce9-c3a50cd247d5', // CTUH_ADD_2 in dev (elevated risk)
    guid05: '6f1784d6-332e-4cdf-9d5e-b1c20b33d2af', // CTUH_ADD_3 in dev (preferred risk)
    guid06: '17f4bbdf-c436-4cc0-b404-665521bf479b', // FE - Netevia/Elevated (dev)
    guid07: 'eb5a60c4-ee8f-4a61-9d4e-cfd598ba7e0b', // FE - Preferred/Repay (dev)
    guid08: 'mock-netevia-merchant-guid-2', // FE - Netevia/Elevated (dev)
    guid09: '43334724-d030-40f2-bd63-ed83b53d0e54', // FE - Priority/Axiom/Elevated
    guid10: '22c288f3-0a99-4a97-8755-795811923915', // FE - Priority/Synovus/Elevated
    guid11: 'cc7d8717-aa0d-4757-8fd0-1fb4a140c9d1', // FE - Priority/Wells Fargo/Elevated
    guid12: 'mock-repay-preferred-relationship-inactive', // FE - Repay/Preferred/Inactive
    guid13: 'fc10b25f-bbcd-441c-b670-07e28a63d9e7', // CTUH_ADD_1_closed
    guid14: 'c1b5519e-6515-46e9-9b9b-840b3df0adb1' // FE - Repay/Preferred - no crab config options
  }
};

export const partnerPortalGuids = {
  merchantGuids: {
    default: '82685a2e-939b-4407-99ef-ebdd22288bfa',
    secondWithData: 'eef0dd9c-d25f-4ce5-8317-b46e947d16be',
    merchant03: 'mock-netevia-merchant-guid-1',
    merchant04: 'mock-netevia-merchant-guid-2'
  },
  partnerGuids: {},
  relationshipGuids: {
    default: [dashboardGuids.partnerPortal.guid01, dashboardGuids.partnerPortal.guid02],
    withNoDelegates: '14c8b2f1-a180-471e-8d4f-67be5915489b',
    withDelegates: 'e74a38bf-e56b-4559-8877-898dffd15320',
    noDelegateAccess: '5d57738f-0e65-4e4f-aa4d-a0a98a614754'
  },
  highRiskGuids: {
    application: Array.from({ length: 30 }).reduce(
      (acc, item, index) => ({
        ...acc,
        [`guid${index + 1}`]: `high-risk-app-guid-${index + 1}`
      }),
      {}
    )
  }
};
