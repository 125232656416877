import React from 'react';
import PropTypes from 'prop-types';
import { input } from './_styles';

export const Total = (props) => {
  const {
    className = null,
    prefix = null,
    label = null,
    id = null,
    value = null,
    wrapperStyle = {},
    customWrapperStyle = {},
    boxStyle = null
  } = props;
  return (
    <div
      className={`${className} totalWrapper`}
      id={id}
      style={{
        ...(boxStyle === 'inside' ? input.innerWrap : input.wrap),
        ...wrapperStyle,
        ...customWrapperStyle
      }}>
      <div className="label" style={boxStyle === 'inside' ? input.labelInside : input.label}>
        {label}
      </div>
      <div
        className="value"
        style={{
          fontSize: '1.6rem',
          padding: '5px',
          width: '100%',
          lineHeight: '1.2'
        }}>
        {prefix && <span className="prefix">{prefix} </span>}
        <span className="value">{typeof value === 'function' ? value() : value}</span>
      </div>
    </div>
  );
};

Total.propTypes = {
  className: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customWrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  boxStyle: PropTypes.string
};

export default Total;
