import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { isEmpty } from '@f1/shared/src/_helpers';

const mockPartnerList = [
  { title: 'Partner 1', value: 'partner-id-01', partnerBusinessCode: '1001' },
  { title: 'Partner 2', value: 'partner-id-02', partnerBusinessCode: '1002' },
  { title: 'Partner 3', value: 'partner-id-03', partnerBusinessCode: '1003' },
  { title: 'Partner 4', value: 'partner-id-04', partnerBusinessCode: '1004' },
  { title: 'Partner 5', value: 'partner-id-05', partnerBusinessCode: '1005' },
  { title: 'Partner 6', value: 'partner-id-06', partnerBusinessCode: '1006' },
  { title: 'Partner 7', value: 'partner-id-07', partnerBusinessCode: '1007' }
];

const createHierarchyItem = (itemOptions) => {
  const { relationshipGuid, relationshipList, targetIndex } = itemOptions || {};
  const relationshipMatch = !isEmpty(relationshipGuid)
    ? relationshipList?.find((r) => r?.value === relationshipGuid) || {}
    : relationshipList?.[targetIndex] || {};
  const partnerMatch = mockPartnerList?.[targetIndex] || mockPartnerList[0];
  const arrayItem = {
    relationship: {
      relationshipId: relationshipMatch?.value || relationshipGuid,
      relationshipName: relationshipMatch?.title || 'Mock Relationship Name',
      relationshipCode: relationshipMatch?.relationshipCode || '20000'
    },
    partner: {
      partnerId: partnerMatch.value,
      partnerBusinessCode: partnerMatch.partnerBusinessCode,
      partnerName: partnerMatch.title
    }
  };
  return arrayItem;
};

const createMockPartnerHierarchy = (options) => {
  const { guid, relationshipList } = options || {};
  const randomLength = getMockValue({ min: 3, max: 6 });
  const hierarchyArray = Array.from({ length: randomLength }).map((item, index) => {
    const isLastItem = index + 1 === randomLength;
    if (isLastItem) {
      return createHierarchyItem({
        relationshipList,
        relationshipGuid: guid // relationship matching request guid
      });
    }
    const arrayItem = createHierarchyItem({
      relationshipList,
      relationshipGuid: null,
      targetIndex: index
    });
    return arrayItem;
  });
  return hierarchyArray;
};

export const mockPartnerHierarchyGet = (body, params, options) => {
  const { guid = '' } = options || {};
  const mockHierarchyData = createMockPartnerHierarchy(options);
  return {
    [guid]: {
      hierarchyData: mockHierarchyData
    }
  };
};

export default mockPartnerHierarchyGet;
