import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet, useLocation } from 'react-router';

export default function PrivateRoute({
  store = false,
  redirectPath = '/signin',
  checkActiveGuid = () => {},
  portal = false
}) {
  const { serverMaintenance } = store.getState().appState;
  const { isAuthenticated, user } = store.getState().authenticate;
  const location = useLocation();
  let Component = Navigate; // = (isAuthenticated && (portalMerchant && location.pathname.includes('/application'))) ? <Navigate /> : <Outlet />
  let targetPath = redirectPath; // = (isAuthenticated && portalMerchant && location.pathname.includes('/application')) ? "/dashboard" : redirectPath;
  let replace = true;
  let exact = false;
  let state = true;

  if (serverMaintenance) {
    targetPath = '/serverMaintenance';
    state = false;
    replace = true;
  }

  if (!serverMaintenance && isAuthenticated) {
    // PORTAL Specific
    portal && checkActiveGuid();
    state = false;
    if (
      portal &&
      !user.isPartner &&
      (location.pathname.includes('/application') || location.pathname.includes('/application-v2'))
    ) {
      // prevent merchants from accessing /application
      exact = true;
      replace = false;
      targetPath = '/dashboard';
    } else {
      targetPath = null;
      Component = Outlet;
    }
  }

  return (
    <Component
      {...(targetPath && { to: targetPath })}
      {...(state && { state: { from: location } })}
      {...(replace && { replace })}
      {...(exact && { exact })}
    />
  );
}

PrivateRoute.propTypes = {
  store: PropTypes.oneOfType([PropTypes.any]),
  redirectPath: PropTypes.string,
  portal: PropTypes.bool,
  checkActiveGuid: PropTypes.func
};
